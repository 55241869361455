.ReportsLabel {
  color: var(--Colors-on-surface, #4F4F4F);
  width: 336px;
  flex-shrink: 0;
  /* Subtitle 1 */
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 140% */
}

.ReportsLabel2 {
  color: var(--Neutral-Black, #25282B);

  /* Headline 6 */
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.03px;
}

.Header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  /* Smaller gap below the header */
}

.ReportsNumber {
  color: var(--Secondary-400, #30584A);
  text-align: center;

  /* Headline 4 */
  font-family: "DM Serif Display";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  /* 125% */
}

.PopupContainer .text {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.PopupContainer {
  display: inline-flex;
  padding: 28px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  background: var(--Colors-surface, #FFF);
  box-shadow: var(--card-shadow);
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1050;
  width: 420px;
}

.ButtonContainer {
  align-items: flex-end;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  justify-content: flex-end;
  position: relative;
  width: 364px;
}

.ReportsBody2 {
  color: #5E5E5E;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.04px;
  margin-bottom: 24px;
}

.PopupListItems {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.PopupListItems>div {
  display: flex;
  column-gap: 5px;
  align-items: center;
}

.selectAllFormCheck {
  font-weight: bold;
  font-size: 17px !important;
}

.selectAllFormCheck label {
  padding-left: 10px;
  font-size: 16px !important;
  line-height: 1.5 !important;
}

.selectAllFormCheck input[type="checkbox"] {
  border: 2px solid #929395;
  width: 25px;
  height: 25px;
}

.ellipsesButton .dropdown-toggle::after {
  display: none;
}

.ellipsesButton .dropdown-toggle {
  padding: 0;
  background: none;
  border: none;
}

.ellipsesButton .dropdown-toggle>span {
  display: flex;
  align-items: center;
}

.TaskListScroll {
  padding-right: 5px;
  max-height: 60vh;
  overflow-y: hidden;
}

.TaskListScroll:hover {
  overflow-y: scroll;
  padding-right: 0px;
}

.CustomDatepicker .react-datepicker__header {
  background-color: #ffffff !important;
}

.buttonLink {
  color: black;
  font-weight: bold;
  font-size: 20px;
  text-decoration: none;
  background-color: transparent;
  border: none;
  padding: 0;
}

.buttonLink:hover {
  color: black;
  text-decoration: underline;
  cursor: pointer;
}

.dateField {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.description {
  display: flex;
  align-items: center;
  margin-left: 80px;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.04px;
}

.unreadMessageIcon {
  position: absolute;
  bottom: 0;
  right: 0;
}