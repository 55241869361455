.InputStyling, .InputStylingPadded, .InputStylingPaddedSelect {
    width: 300px;
    height: 40px;
    padding: 10px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 0.5px solid #929395;
    font-size: 14px !important;
}

.InputStylingPadded {
    padding: 20px 12px 10px 10px;
}

.InputStylingPaddedSelect {
    padding: 15px 12px 5px 5px;
}

.InputDropdownContainer {
    width: 300px;
    height: 44px;
    padding: 10px 12px;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 0.5px solid #929395;
    display: flex;
    justify-content: space-between;
}

.MultiSelectDropdownListContainer {
    display: flex;
    width: 300px;
    padding: 20px 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    background-color: white;
    position: absolute;
    border-radius: 12px;
    border: 0.5px solid #CFCFCF;
    z-index: 3;
    box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.10);
    max-height: 300px;
    overflow-y: scroll;
}

.MultiSelectDropdownLabel, .MultiSelectDropdownLabelActive, 
.MultiSelectDropdownLabel:hover {
    color: #4A4A4A;
    font-family: "Lato";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.2px;
    display: flex;
    column-gap: 5px;
    width: 100%;
    padding-left: 10px;
    border-radius: 6px;
    white-space: nowrap;
}

.MultiSelectDropdownLabelActive {
    background-color: #006969;
    color: white;
}

.MultiSelectDropdownLabel:hover{
    background-color: #E8F5F5;
}

.FloatingLabel {
    color: #929395;
    font-family: "Lato";
    font-weight: 400;
    line-height: 16px;
    padding: 3px 0 0 10px;
    position: absolute;
    font-size: 12px;
}

.NoteInput {
    width: 100%;
    padding: 17px 12px 10px 10px;
    border: 0.5px solid #929395;
    height: 100px;
    max-height: 200px;
    min-height: 44px;
    border-radius: 6px;
    font-size: 14px !important;
    font-weight: 400;
}

.ValidationMessage {
    font-size: 10px;
    color: red;
}

.ValidationMessageContainer {
    display: flex;
    column-gap: 5px;
    padding-left: 5px;
}


.HideScrollContainer {
    height: 100%;
    overflow: scroll;
    padding-bottom: 20px;
}