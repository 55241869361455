.AlertContainer {
   position: absolute;
   z-index: 999;
   max-width: 250px;
   right: 0;
   margin-right: 80px;
   margin-top: 50px;
}

.AlertContainer .close {
   padding: 10px 10px 0 0 !important;
}

.alert-dismissible .btn-close {
   padding: 10px 10px 0 0 !important;
}

.AlertHeading, .AlertBody {
   font-family: "Roboto" !important;
   font-style: normal;
   font-weight: 500;
}

.AlertHeading {
   font-size: 16px !important;
   display: flex;
   column-gap: 10px;
   height: 20px;
   margin-bottom: none !important;
   justify-content: space-between;
}

.AlertBody {
   font-size: 12px !important;
   overflow-wrap: break-word;
   width:100%;
}

.Alert {
   padding: 8px 10px !important;
}

.successAlert {
   background-color: #30584A;
   border-color: #30584A;
   color: white;
}
 
.dangerAlert {
   background-color: #f8d7da;
   border-color: #f5c6cb;
   color: #721c24;
}
 
.warningAlert {
   background-color: #fff3cd;
   border-color: #ffeeba;
   color: #856404;
}
 
.infoAlert {
   background-color: #d1ecf1;
   border-color: #bee5eb;
   color: #0c5460;
}