.create-main-content {
    background-color: #f9f9f9;
    height: 85vh;
    display: flex;
    flex-direction: column;
    transition: margin-left 0.3s;
}

.middle-content-create {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 90vh;
    background-color: #FFFFFF;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    box-shadow: 0px 20px 24.200000762939453px 0px #0000000F;
}

.next-button, .back-button {
    border: none;
    font-family: 'Lato';
    font-size: 16px;
    font-weight: 700;
    width: 128px;
    height: 35px;
    border-radius: 6px;
}

.next-button{
    color: white;
}

.back-button {
    border: 1px solid #006969;
    background-color: white;
    color: #006969;
}

/* create account styles */
.return-to-account {
    padding-left: 2%;
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #4A4A4A;
    cursor: pointer;
    margin-bottom: 0px;
}

.content-title {
    padding: 10px 0px 0px 20px;
    font-size: 28px;
    font-weight: 700;
    line-height: 37px; 
    color: #515772;
}


/* Progress bar styles */
.progress-steps {
    color: #3b3e40;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.10000000149011612px;
    display: inline-flex;
    padding: 12px 0px;
    align-items: center;
}

.stepper-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
}

.circle-active, .circle-complete, .circle-inactive{
    width: 18px;
    height: 18px;
    line-height: 30px;
    border-radius: 50%;
    display: inline-block;
}

.circle-active{
    background-color: #68A691;
    color: #0CCBC3;
    border: 2px solid white;
    outline: 1px solid #68A691;
}

.circle-inactive{
    background-color: #D6D6D6;
    color: #D6D6D6;
    border: 2px solid #D6D6D6;
    outline: 1px solid #D6D6D6;
}

.circle-complete{
    background-color: #30584A;
    color:#66696B;
    border: 2px solid #30584A;
    outline: 1px solid #30584A;
}

.check-icon {
    color: white;
    margin-bottom: 45px;
    position: absolute;
}

.line-container {
    display: flex;
    height: 44px;
    padding: 14px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.line, .line-complete {
    flex-grow: 1; /* Lines grow to fill space */
    height: 1px;
    border: 1px solid #D6D6D6;
    width: 44px;
    margin-bottom: 40px;
}

.line-complete {
   border: 1px solid #4F4F4F;
}

.step-active, .step-complete {
    color: #4F4F4F;
}

.step-inactive{
    color: #D6D6D6;
    font-weight: 400;
}


@media (max-width: 1000px) {
    .reg-steps {
        width: 70%;
        max-width: 300px;
        font-size: 10px;

    }

    .stepper-item div{
        width: 20px;
        height: 20px;
        line-height: 30px;
        border-radius: 50%;
        display: inline-block;
        margin: 0px;
    }

    .reg-steps {
        font-size: 11; /* adjust font size */
    }

    .create-account-step-container{
        width: 90%;
    }
}

.login-container {
    display: flex;
    flex-direction: column;
    width: 392px;
    height: 561px;
    padding: 32px 0px;
    flex-direction: column;
    flex-shrink: 0;
    margin: auto;
    gap: 24px;
    background-color: white;
    align-items: center;
    border-radius: 16px;
    box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.10);
}

@media (max-width: 768px) {
    .login-container {
        width: 80%;
        height: 70%
    }
}


.activate-account-container {
    display: flex;
    flex-direction: column;
    width: 40vw;
    height: 80vh;
    flex-direction: column;
    flex-shrink: 0;
    margin: auto;
    gap: 24px;
    background-color: white;
    align-items: center;
    border-radius: 16px;
    box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.10);
}

/* @media (max-width: 821px) {
    .activate-account-container {
        width: 400px;
        height: 600px;
    }
} */

.activate-account-inner-container {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    background-color: white;
    align-items: flex-start;
    margin: 0 10%;
    overflow: auto;
    height: 100%;
}


/*  form styles */
.create-account-inputs{
    display: flex;
    flex-direction: column;
    font-family: "Lato";
    row-gap: 10px;
    overflow: auto;
    padding-bottom: 20px;
}

.input-name{
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #1F1F1F;
}

.unit-types{
    font-family: "Lato";
    font-size: 13px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
    color: #3B3E40;
}

.group-button{
    width: 113px;
    height: 44px;
    min-height: 44px !important;
    border-radius: 25px;
    background: var(--Web-Colors-btn-bg-tertiary-solid-default, #B7C0EE);
    border: none;
    color: #212241;
    font-family: "Roboto";
    font-size: 16px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    letter-spacing: 0.64px;
}

.note-input {
    width: 300px;
    height: 100px;
    max-height: 200px;
    min-height: 44px;
    padding: 16px 12px 0 12px;
    border-radius: 6px;
    border: 0.5px solid var(--Colors-ol-input-default, #686869);
    background-color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
    color: #000000;
}

/* .create-account-inputs > input,
.create-account-inputs > select,
.create-account-inputs > div > input,
.input1 {
    width: 300px;
    height: 44px;
    padding: 10px 12px 10px 12px;
    border-radius: 6px;
    border: 0.5px solid var(--Colors-ol-input-default, #686869);
    background-color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
    line-height: 38px;
    color: #25282B;
}  */

/* ... (keep existing styles) */

.selected-teams {
    display: flex;
    list-style: none;
    padding-left: 0px;
    width: 100%;
    flex-wrap: wrap;
}

.li-team {
    transition: background-color 0.3s ease;
    display: flex;
    margin-right: 10px;
    margin-top: 10px;
    border-radius: 22px;
    color: #000000;
    font-family: "Roboto";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    padding: 10px;
    justify-content: center;
    align-items: center;
    height: 35px;
    column-gap: 10px;
    cursor: pointer;
    background: var(--Colors-bg-actionchip-default, #D6D6D6);
    border: 0.5px solid var(--Colors-ol-actionchip-selected, #D6D6D6);
}

.team-remove-icon {
    margin-left: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
}

.specialty-select .multiselect-checkbox input[type='checkbox'] {
    accent-color: #006969; /* For modern browsers */
}

.specialty-select .multiselect-dropdown-list .multiselect-item:hover {
    background-color: red; /* Replace with your highlight color */
}

.primary-specialties {
    display: flex;
    list-style: none;
    padding-left: 0px;
    width: 100%;
    flex-wrap: wrap;
    
}

.li-primary, .li-specialty {
    display: flex;
    margin-right: 10px;
    margin-top:10px;
    border-radius: 22px;
    color: #000000;
    font-family: "Roboto";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    padding: 10px;
    justify-content: center;
    align-items: center;
    height: 35px;
    column-gap: 10px;
    cursor: pointer;
}

.inputs {
    flex: 1;
    overflow: auto;
    padding-top:15px;
}

.li-specialty{
    background: var(--Colors-bg-actionchip-selected, #B2C7CE);
    border: 0.5px solid var(--Colors-ol-actionchip-selected, #1D5769);
}

.li-primary {
    background: var(--Colors-bg-actionchip-default, #D6D6D6);
    border: 0.5px solid var(--Colors-ol-actionchip-selected, #D6D6D6);
}

.button-container1 {
    display: flex;
    align-items: center;
    box-shadow: 0px -2px 9.699999809265137px 0px rgba(0, 0, 0, 0.08);
    height: 60px;
    gap: 10px;
}

.button-container2 {
    display: flex;
    align-items: center;
    height: 70px;
    width: 100%;
    justify-content: space-between;
}

.input-block{
    height: 44px;
    display: flex;
    justify-content:space-between;
    margin-bottom: 10px;
    width: 300px;
}

.unit-input {
    display: flex;   
}

.eye-icon {
    color: #929395;
    z-index: 10;
}

.icon-container {
    position: absolute;
    margin-left: -32px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}


/* group select styles */

.group-select-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.group-select-container {
    position: absolute;
    width: 420px;
    height: 583px;
    background-color: #FFFFFF;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 6px;
}

.group-select-content{
    width: 90%;
    height: 90%;
    margin: auto;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
}

.selected-item {
    color: #595A5C;
    font-family: "Lato";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.1px;
    list-style: none;
    
}

.close-button {
    background-color: #FFFFFF;
    border: none;
    outline: none;
    cursor: pointer;
    top: 10px;
    right: 20px;
    position: absolute; 
    font-size: 32px;
}
.close-button:hover {
    background-color: none;
    color: none;
}

.groups-list {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    padding-left: 0;
    margin-top: 10px;
}

.group-list-item {
    color: #595A5C;
    padding-left: 10px;
    display: flex;
    max-width: 600px;
    height: 52px;
    min-width: 208px;
    padding: 10px 12px;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #D9D9D9;
    justify-content: space-between;
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.04px;
}

.group-list-item:first-child{
    border-top: 1px solid #D9D9D9;
}

.read-only-input, .edit-account-input {
    color: #929395;
    font-family: "Lato";
    font-weight: 400;
    line-height: 16px;
}

.read-only-input {
    background-color: #F4F4F4;
    padding: 17px 12px 10px 10px;
}

.read-only-input, .edit-account-input {
    border-radius: 6px;
    border: 0.5px solid var(--Colors-ol-input-default, #686869); 
    font-size: 12px;
    width: 100%;
    height: 44px;
}

.edit-account-input {
    padding: 20px 12px 10px 10px;
    background-color: white;
    color: #25282B;;
}

.personal-info-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1; /* Allow the container to grow to fill available space */
    overflow: auto;
}

.personal-info-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1; /* Allow the content container to grow */
    flex-shrink: 1; /* Allow the content container to shrink if necessary */
    gap: 10px;
}

.floating-label, .floating-label-disabled {
    padding: 3px 0 0 12px;
    position: absolute;
    font-size: 11px !important;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    color: #4F4F4F;
}

.floating-label-disabled {
    color: hsl(0, 0%, 60%) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}


.tree-outer-wrap {
    display: flex;
    height: 100%;
    overflow: hidden;
  }

  
  .tree-inner-wrap {
    flex: 1 1 auto;
    width: 100%;
  }


/* welcome styles */
.welcome-container {
    width: 34%;
    height: 100vh;
    display: flex;
    background: #C4E1E01A;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 0;
    padding-left: 25px;
    padding-right: 25px;
  }

  .welcome-title {
    color: #3b3e40;
    font-family: 'Rubik';
    font-weight: 500;
    font-size: 35px;
    line-height: 30px;
    letter-spacing: -0.18px;
    margin-bottom: 0;
  }
  
  .account {
    color: #3b3e40;
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 19px;
    line-height: 28px;
    letter-spacing: 0.2px;
  }

/* login styles */

.create-account-step-container{
    width: 80%;
    display: flex;
    flex-direction: column;
}

.logo-body {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    margin-right: 30px;
    width: 100px;
    height: 100px;
}

.reg-logo {
    font-family: 'Lato';
    font-weight: 400;
    line-height: 18.62px;
    padding-right: 5px;
    font-size: 20px;
}

.reg-title {
    color: var(--Colors-page-title, #00162E);
    text-align: center;
    
    /* Headline 4 */
    font-family: "DM Serif Display";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 125% */
    font-weight: bold;
}

/* CreationSteps styles */
.reg-steps {
    color: #3b3e40;
    font-family: 'Lato';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    list-style: none;
    padding-bottom: 20px;
    width: 100%;
    max-width: 550px;
    
}
