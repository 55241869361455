.header-row {
  /* margin-bottom: 25px; */
  font-weight: bold;
  color: #4a4a4a;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 10px;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: white; */
  /* flex-wrap: wrap; */
}

.header-row-section {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.appointments-container {
  background-color: white;
  margin: 0px 20px;
  border-radius: 8px;
  box-shadow: 0px 20px 25px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  height: 89vh;
}


.header-text {
  color: #006969;
  font-family: "Segoe UI";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.header-vector-button {
  padding: 5px !important;
  min-width: 10px !important;
}

.header-button {
  display: flex;
  height: 44px;
  padding: 0px 22px;
  align-items: center;
  gap: 10px;
  border-radius: 25px;
  background: #B7C0EE;
  float: right;
  cursor: pointer;
}

.header-button-label, .header-button-label-white {
  font-family: "Roboto";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px; /* 150% */
  letter-spacing: 0.64px;
  color: #212241;
  display: flex;
  align-items: center;
  gap: 5px;
}


.header-button-label-white {
  color: #363E6B;
}

.header-button-white {
  display: flex;
  height: 44px;
  padding: 0px 22px;
  align-items: center;
  gap: 10px;
  background: white;
  float: right;
  color: #363E6B;
  border-radius: 25px;
  border: 0.5px solid var(--Neutral-800, #363E6B);
  cursor: pointer;
}

.header-dropdown {
  float: right;
  border-radius: 6px;
  border: 0.5px solid var(--Colors-ol-input-default, #686869);
  background: var(--Colors-bg-input-default, #fff);
  display: flex;
  height: 44px;
  min-width: 15vw;
  padding: 10px 12px;
  align-items: center;
  gap: 10px;
}

.header-dropdown-btn-txt {
  color: var(--Colors-text-label-default, #4f4f4f);
  font-family: "Roboto";
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px; /* 150% */
  letter-spacing: 0.024px;
  text-transform: none;
}

.header-dropdown-btn-icon {
  color: var(--Colors-text-label-default, #4f4f4f);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
   line-height: 15px; /* 150% */
  letter-spacing: 0.024px;
  margin-left: 10px;
}

.action-button {
  margin-right: 5px;
}

.action-button:last-child {
  margin-right: 0;
}

.appointment-icon {
  width: 14.1px;
  height: 14.1px;
  fill: var(--Neutral-White, #fff);
  stroke-width: 1px;
  stroke: var(--Neutral-Black, #000);

  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.appointment-content {
  width: 40% !important;
  flex-wrap: wrap !important;
  gap: 4px !important;
  padding-right: 10px !important;
}

.appointment-container {
  display: flex !important;
  flex-direction: column !important;
  border-radius: 15px !important;
}

.text-calendar-container {
  display: flex;
  justify-content: space-between;
  height: 10%;
  padding-bottom: 10px;
}

.appointments-title {
  color: #4f4f4f;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.appointments-upcoming {
  color: #686869;
  font-family: "DM Sans";
  font-size: 16px;
  font-style: normal;
  line-height: normal;
}

.patient-appointment-container {
  height: 202px;
  overflow: auto;
}

.calendarView {
  display: flex;
  flex-wrap: nowrap;
  background-color: white;
  margin: auto;
  width: 99%;
  padding-bottom: 10px;
}

.hourColumn {
  position: relative;  /* Container must be positioned */
  min-height: 60px;        /* Ensure it has a defined height */
  overflow:visible;
}

.dayColumn {
  display: flex;
  flex-direction: column;
  border-left: 1px solid #d6d6d6;
  flex: 1 1 0px;
  min-width: 0;
  position:relative;
  height:auto;
}

/* .dayColumnToday {
  border-left: 0.5px solid #1D5769; 
  border-right: 1px solid #1D5769; 
} */

.borderRight {
  border-right: 1px solid #D6D6D6;
}

.borderLeft {
  border-left: 1px solid #D6D6D6;
}

.hourSlot {
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  width: 100%;

  padding: 8px;
  border-bottom: 1px solid #D6D6D6;
  background-color: #fff;
  height: 150px;
  color: var(--Neutral-600, #686869);
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dayHeaderFirst,
.dayHeaderSecond {
  line-height: 1.2;
}

.appointment {
  border-left: 3px solid #1D5769;
  cursor: pointer;
  margin: 2px;
  padding: 4px;
  cursor: pointer;
  z-index:8;
  position: absolute; 
  width: 99% !important;         
  left: 0;     
  border-bottom: 1px solid white;       
}

.appointment:hover {
  background-color: #2b2d2f;
}

.appointmentPatient {
  color: var(--Secondary-300, #457968);
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.dayHeaderFirst {
  /* Headline 6 */
  font-family: "Roboto";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 140% */
  letter-spacing: 0.03px;
}

.dayHeaderSecond {
  /* Headline 4 */
  font-family: "DM Serif Display";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 125% */
}

.dayHeader, .dayHeaderToday {
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  background: white;
  color: red;
  border-bottom: 1px solid var(--Neutral-200, #d6d6d6);
}

.dayHeader {
  color: var(--Colors-text-calendar-day-inactive, #686869);
  border-top: 1px solid var(--Neutral-200, #d6d6d6);
  position: sticky;
  top: 0;
  z-index: 10; 
}

.dayHeaderToday {
  position: sticky;
  top: 0;
  color: #00162E;
  border-top: 3px solid #1D5769;
  padding-top: 7px; /* Compensate for border height if necessary */
  z-index: 11; 
}

.dayHeaderPlaceholder {
  height: 90px;
  z-index: 11;
  top: 0;
  position: sticky;
  background-color: white;
  border-top: 1px solid var(--Neutral-200, #d6d6d6);
  border-left: 1px solid var(--Neutral-200, #d6d6d6);
  border-bottom: 1px solid var(--Neutral-200, #d6d6d6);
}

.monthView {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  height: 90%;
}

.dayNames {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  height: 10%;
  place-items: center;
}

.monthDayName {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: "DM Serif Display";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 125% */
  color: #4F4F4F;
  width: 100%;
  height: 100%;
  border: 0.5px solid var(--Colors-ol-day, #D6D6D6);
  padding: 10px;
}

.dateHeader {
  padding-bottom: 10px;
}

.daySquare, .daySquareToday {
  padding: 10px;
  min-height: 100px;
  font-family: "Roboto";
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  overflow-y: auto; /* Enable scrolling */
  max-height: 120px; /* Set a maximum height for the scroll area */
  border: 0.5px solid var(--Colors-ol-day, #D6D6D6);
  background-color: #fff;
  font-weight: 500;
  color: #4F4F4F;
}

.daySquare {
  border: 0.5px solid var(--Colors-ol-day, #D6D6D6);
  background-color: #fff;
  font-weight: 500;
  color: #4F4F4F;
}

.currentMonth {
  color: #4F4F4F;
}

.otherMonth {
  background: var(--Colors-bg-calendar-cell-unavailable, #F3F3F3);
}

.daySquareToday {
  border-top: 3px solid var(--Primary-500, #1D5769);
  border-right: 0.5px solid var(--Primary-500, #1D5769);
  border-left: 0.5px solid var(--Primary-500, #1D5769);
  border-bottom: 0.5px solid var(--Primary-500, #1D5769);
  background: #F3F3F3;
  color: var(--Colors-text-calendar-date-active, #00162E);
  font-weight: 700;
}

.inputPlaceholder {
  color: #929395;

  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
  letter-spacing: 0.1px;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}

#appointmentDate:before {
  content: "Date:";
  margin-right: 0.6em;
  color: #9d9d9d;
}

.formHeader {
  color: var(--Colors-on-surface, #4F4F4F);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
   line-height: 15px; /* 150% */
  letter-spacing: 0.024px;
  /* margin-bottom: 12px; */
}

.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  display: flex;
  flex-direction: column;
  max-height: 90vh; 
  overflow-y: auto; 
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.popup {
  font-size: "14px";
}

.basic-multi-select__value-container {
  min-height: 38px;
  align-items: center;
}

.detailHeader {
  color: var(--Colors-on-surface, #4f4f4f);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
   line-height: 15px;
  letter-spacing: 0.024px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  padding-right: 20px;
  column-gap: 5px;
}

.videoHeader {
  border-bottom: 0.25px solid var(--Colors-card-keyline, #B9B9B9);
  padding-bottom: 10px;
}

.videoMiddle {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.VideoHeaderText {
  color: var(--Colors-on-surface, #4f4f4f);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
   line-height: 15px;
  letter-spacing: 0.024px;
  white-space: nowrap;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.videoWelcomeText{
  color: var(--Colors-page-title, #00162E);
  text-align: center;

  /* Subtitle 1 */
  font-family: "Roboto";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 140% */
}

.videoEnsure{
  color: var(--Colors-on-surface, #4F4F4F);
  text-align: center;

  /* Body 2 */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
   line-height: 15px; /* 150% */
  letter-spacing: 0.04px;
}

.detailDropdown {
  border-radius: 6px;
  border: 1px solid var(--Web-Colors-btn-ol-secondary-default, #00162e);
  background: white;
  color: #00162E;
}

.startCallButton, .detailDropdown {
  display: flex;
  height: 40px;
  min-width: 116px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  text-align: center;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
   line-height: 15px; /* 150% */
  letter-spacing: 0.64px;
}

.startCallButton {
  background: var(--Web-Colors-btn-bg-primary-default, #1D5769);
  color: white;
  border: 1px solid var(--Web-Colors-btn-ol-secondary-default, #1D5769);
}

.detailModel {
  width: 10%;
}

.modalAppointmentTime {
  color: var(--Colors-on-surface, #4F4F4F);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
   line-height: 15px; /* 150% */
  letter-spacing: 0.04px;
  padding: 10px 0 20px 0;
}

.iconContainer {
  display: flex !important;
  align-items: center !important;
  position: relative;
  gap: 5px !important;
  width: 40px;
}

.iconText {
  color: #212121;
  color: var(--Colors-text-avatar, #212121);
  display: flex;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  justify-content: center;
  letter-spacing: 0.5px;
  line-height: 16px;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 2;
}

.formClose {
  border-radius: 6px;
  border: 1px solid var(--Web-Colors-btn-ol-secondary-default, #00162e);
  background: var(--Web-Colors-btn-bg-secondary-default, #fff);
  display: flex;
  width: 128px;
  height: 40px;
  min-width: 116px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-right: 10px;
}

.formSubmit {
  display: flex;
  height: 40px;
  width: auto;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: var(--Web-Colors-btn-bg-primary-default, #1D5769);
  border: none;
  color: var(--Web-Colors-btn-on-primary, #FFF);
  text-align: center;
  /* Button */
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
   line-height: 15px; /* 150% */
  letter-spacing: 0.64px;
}

.formFooter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.rowContent{
  display: flex;
  padding: 20px 0px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.appointmentMonth{
  border-left: 3px solid var(--Secondary-300, #457968);
  background: rgba(203, 243, 210, 0.2);
  margin: 2px 0 2px -10px;
  display: flex;
  padding: 4px;
  align-items: center;
  cursor: pointer;
  height: 17.5px;
  white-space: nowrap;
}

.appointmentMonthDetails{
  font-family: "Roboto";
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%; 
}

.inMeetingMsg, .inMeetingMsg:hover{
  font-size:12px;
  font-weight:bold;
  padding-left: 5px;
  color: white;
}

.videoContainer {
  position: relative;
  height: 100%; /* Set to the height of your choice */
}

.videoElement {
  transition: all 0.3s ease;
  object-fit: contain; /* Maintains aspect ratio and fits within the container */
  width: auto; /* Allows the video to scale properly */
  height: 100%; /* Keeps the full height */
  max-height: 100%;
  max-width: 100%;
}

.localVideo, .localVideoMinimized {
  height: auto;
  top: 10px;
  right: 1%;
  z-index: 2; /* Higher index to overlap */
  border: 1px solid white;
  position: absolute;
}

.localVideo {
  width: 20%;
  border-radius: 12px;
}

.localVideoMinimized {
  width: 30%;
  border-radius: 5px;
}

.remoteVideo {
  width: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  position:absolute;
}

.videoCol{
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.videoContainer {
  position: relative;
  height: 100%;
  overflow: hidden; /* Adjust based on your design needs */
}

.videoRow{
  overflow: hidden; /* Prevents content from spilling out */
  position: relative; /* Sets positioning context for absolute children */
  width: 100%; /* Ensures the container fits its parent */
  height: 500px; /* Adjust based on content or fixed height */
}

.previewVideo{
  border-radius: 14px;
  transition: all 0.3s ease;
  object-fit: contain; /* Maintains aspect ratio and fits within the container */
  width: auto; /* Allows the video to scale properly */
  height: auto; /* Keeps the full height */
}

.appointmentDetails {
  overflow: hidden; 
  text-overflow: ellipsis; /* Adds an ellipsis to indicate hidden text */
  padding: 0px 4px; 
  transition: all 0.3s ease;
  font-size:12px;
  max-height: 100%;
  display: flex;
  box-sizing: border-box;
  font-family: "Roboto";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.appointmentDetails:hover {
  overflow: visible; 
  color: black;
  z-index: 10; 
  position: absolute;
  width: auto; 
  max-width: 200%; 
  white-space: normal; 
}

.appointment.hideText .appointmentDetails,.appointment.hideText .appointmentDetailsWaitingRoom {
  visibility: hidden;
  font-size: 0;
}

.appointment.hideText:hover .appointmentDetails, .appointment.hideText:hover .appointmentDetailsWaitingRoom {
  visibility: visible;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.appointmentDetailsWaitingRoom {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
}

.appointment:hover .ellipsis {
  display: none; 
}

.ellipsis {
  position: absolute;
  top: 0;
  left: 0;
  width: 10%;
  height:90%;
  text-align: center;
  background-color: inherit; 
  z-index: 2; 
}

.summaryHeader{
  color: var(--Colors-on-surface, #4F4F4F);

  /* Headline 6 */
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 140% */
  letter-spacing: 0.03px;
}

.summarySectionHeader{
  color: var(--Colors-on-surface, #4F4F4F);
  /* Subtitle 2 */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
   line-height: 15px; /* 150% */
  letter-spacing: 0.024px;
}

.summaryBody{
  color: var(--Colors-on-surface, #4F4F4F);
  /* Body 2 */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
   line-height: 15px; /* 150% */
  letter-spacing: 0.04px;

  display: inline-flex;
  padding: 36px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.AddTimeSlotButton {
  width: 128px;
  height: 30px;
  border-radius: 6px;
  background-color: white;
  border: none;
  color: var(--Web-Colors-btn-text-default, #1D5769);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
   line-height: 15px; /* 150% */
  letter-spacing: 0.64px;
}

.AddTimeSlotButton:hover {
  background-color: white;
  color: var(--Web-Colors-btn-text-default, #1D5769);
}

.delete-button {
  padding: 10px;
  min-width: 20px;
  background-color: white;
  border: none;
}

.delete-button:hover {
  background-color: white;
}

.audioPlayerContainer {
  margin-bottom: 20px;
}

.audioPlayer {
  width:100%;
}

.content {
  display: flex;
  justify-content: space-between;
}

.transcriptions, .insights {
  flex: 1;
  padding: 10px;
  overflow-y: scroll;
  height: 400px; /* Adjust height as needed */
  color: var(--Colors-on-surface, #4F4F4F);
  /* Body 2 */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
   line-height: 15px; /* 150% */
  letter-spacing: 0.04px;
  padding-left: 28px;
  margin-right:10px;

  border-radius: 6px;
  border: 0.5px solid var(--Colors-ol-card, #D6D6D6);
  background: var(--Colors-background, #F3F3F3);
}

.transcriptionDetail{
  color: var(--Colors-on-surface, #4F4F4F);
  /* Body 2 */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px; /* 150% */
  letter-spacing: 0.04px;
  padding-left:28px;
}

.transcriptionDetailHeader{
  color: var(--Colors-on-surface, #4F4F4F);
  /* Subtitle 2 */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 150% */
  letter-spacing: 0.024px;
  padding-left:28px;
}

.audioHeader{
  color: var(--Colors-on-surface, #4F4F4F);

  /* Subtitle 2 */
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
   line-height: 15px; /* 150% */
  letter-spacing: 0.024px;
  padding-left:28px;
  padding-top:20px;
}

.highlight {
  background-color: yellow;
  text-decoration: underline;
}

.transcriptionModal .modal-dialog {
  max-width: 80% !important;
  width: 80%;
}



.transcriptionTitle{
  color: var(--Colors-on-surface, #4F4F4F);
  /* Headline 6 */
  font-family: Roboto;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 140% */
  letter-spacing: 0.03px;
  padding-left:28px;
  padding-top:28px;
}

.ChatContainer {
  width: 460.9px;
  max-height: 69vh;
  min-height: 69vh;
  overflow: auto;
  background-color: #f9f9f9;
  border-left: 1px solid #ddd;
  padding: 10px 10px;
  z-index: 100;
  border-radius: 12px;
  background: var(--Colors-surface, #FFF);
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.10);
  position: relative;
}

@media (min-width: 1400px) {
  .ChatContainer {
      max-height: 74vh;
  }
}

.StartCallAppointment, .EndCallAppointment {
  display: flex;
  height: 40px;
  width: 100%;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  text-align: center;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
   line-height: 15px; /* 150% */
  letter-spacing: 0.64px;
  border-radius: 6px;
}

.StartCallAppointment{
  background: var(--Web-Colors-btn-bg-primary-default, #1D5769); 
  border: 1px solid #1D5769; 
  color: white;
}

.EndCallAppointment{
  color: var(--Web-Colors-btn-on-secondary, #00162E);
  border: 1px solid var(--Web-Colors-btn-ol-secondary-default, #00162E);
  background: var(--Web-Colors-btn-bg-secondary-default, #FFF);   
}

.AppointmentToggleButtons {
  background: white;
  border: none;
}

.AppointmentToggleButtons:hover {
  background: white;
  border: none;
}

.endCallButtonAppointments {
  display: flex;
  height: 40px;
  width: 130px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-align: center;
  font-family: "Roboto" !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
   line-height: 15px; /* 150% */
  letter-spacing: 0.64px;
  border-radius: 6px;
  background: var(--Web-Colors-btn-bg-primary-destructive, #9F1919);
  border: none;
  color: var(--Web-Colors-btn-on-primary, #FFF);

}

.moreAppointments {
  color: var(--Neutral-Black, #000);
  font-family: "Roboto";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  cursor: pointer;
}

.MoreAppointmentsCard {
  background: #fff;
  border: .5px solid #cfcfcf;
  border-radius: 12px;
  box-shadow: 1px 1px 15px 0 #0000001a;
  display: flex;
  flex-direction: column;
  height: 100px;
  justify-content: space-between;
  padding: 5px;
  position: absolute;
  /* transform: translateX(200%) translateY(200%); */
  width: 200px;
  z-index: 3;
}

.appointmentPopupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.appointmentPopup {
  position: absolute;
  background-color: white;
  display: inline-flex;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 12px;
  border: 0.5px solid var(--Colors-ol-surface, #D6D6D6);
  background: var(--Colors-surface, #FFF);
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.10);
  color: var(--Colors-on-menu, #000);
  /* Button */
  font-family: "Roboto";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
   line-height: 15px; /* 150% */
  letter-spacing: 0.64px;
}

.appointmentPopupDate {
  padding: 8px;
}

.appointmentPopupItem {
  display: flex;
  height: 28px;
  padding: 0px 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  cursor: pointer;
}

.appointmentPopupItem:hover {
  background: var(--Colors-bg-item-active, #B2C7CE);
}

.audioNotReady {
  color: #666;
  padding: 20px;
  font-size: 20px;
  text-align: center;
}

.AppointmentCallContainerMinimized, .AppointmentCallContainer {
  overflow: hidden;
  background-color: #f9f9f9;
  border-left: 1px solid #ddd;
  padding: 10px 10px;
  z-index: 2;
  border-radius: 12px;
  background: var(--Colors-surface, #FFF);
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.10);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.AppointmentCallContainer {
  width: 70vw;
  height: 80vh;
}

.AppointmentCallContainerMinimized {
  width: 20vw;
  height: 35vh;
}