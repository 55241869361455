.PopupContainer {
    position: absolute;
    width: 500px;
    height: 600px;
    background-color: #FFFFFF;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 6px;
    padding: 10px 28px 10px 28px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    justify-content: space-around;
}

.SaveButton, .CancelButton {
    display: flex;
    width: 128px;
    height: 40px;
    min-width: 116px;
    padding: 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0;
    border-radius: 6px;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    border: 1px solid #1D5769;
}

.SaveButton {
    background: #1D5769;
    color: white;
}

.CancelButton {
    background: white;
    color: #1D5769;
}

.ButtonContainer {
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    padding-top: 10px;
    column-gap: 10px;
    border-top: 1px solid #C5C7C9;
}

.CloseOut {
    align-self: end;
    cursor: pointer;
}
