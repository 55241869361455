.DropdownContainer {
    background: #FFF;
    box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.10);
    border-radius: 16px;
}

.CardTitleOpened, .CardTitleClosed {
    display: flex;
    gap: 10px;
    width: 100%;
    height: 52px;
    padding: 10px 20px;
    align-items: center;
    border-radius: 16px 16px 0px 0px;
    border: 0.5px solid #BEBFBF;
    background: var(--Neutral-White, #FFF);
}

.CardTitleLabel {
    color: var(--Neutral-900, #212121);
    font-family: "Roboto";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
}

.CardTitleClosed {
    border-radius: 16px 16px 16px 16px;
}

.Interval {
    color: #4F4F4F;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.024px;

}

.IntervalRow {
    display: flex;
    column-gap: 10px;
    align-items: center;
}

.Label {
    color: #4F4F4F;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 123.077% */
    letter-spacing: 0.04px;
    font-family: 'Roboto';

}

.OpenCardContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: var(--Neutral-White, #FFF);
    width: 100%;
    border: 0.5px solid #BEBFBF;
    border-radius: 0px 0px 16px 16px;
}

.ModalitiesList {
    display: flex;
    padding-right: 10px;
    /* overflow: hidden; */
    width: 100%;
    justify-content: flex-start;
    align-items: center;
}

.ModalityLabel, .ModalityLabelActive {
    display: flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 60px;
    text-align: center; 
}

.ModalityLabel {
    border-bottom: 0.5px solid var(--Colors-on-tab-default, #686869);
    color: var(--Colors-on-tab-default, #686869);
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.04px;
}

.ModalityLabelActive {
    border-bottom: 2px solid var(--Primary-500, #00162E);
    color: #00162E;
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.024px;
    background: #E1ECEF;
}

.ModalityMeasurementLabel {
    color: #3F4346;
    font-family: "Lato";
    font-size: 15px !important;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; 
    letter-spacing: 0.1px;
}