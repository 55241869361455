.DropdownContainer {
    width: 740px;
    background: #FFF;
    box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.10);
    border-radius: 16px;
}

.ClosedPatientCard, .OpenPatientCard {
    border: 0.5px solid #BEBFBF;
    padding: 20px;
}

.OpenPatientCard {
    border-radius: 16px 16px 0px 0px;
}

.ClosedPatientCard {
    border-radius: 16px;
}


.Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    letter-spacing: 0.04px;
    font-weight: 400;
    line-height: 24px;
    cursor: pointer;
}

.HeaderLeft, .HeaderRight {
    display: flex;
    align-items: center;
    column-gap: 10px;
    width: auto;
}

.BorderLeftLabel {
    padding-left: 10px;
    border-left: 0.5px solid var(--Neutral-400, #9D9D9E);
}

.CardTitle {
    letter-spacing: 0.024px;
    font-weight: 500 !important;
}

.PatientCardBody {
    border-radius: 0px 0px 16px 16px;
    border: 0.5px solid #BEBFBF;
    border-top: none;
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

.CardTag {
    color: white;
    font-family: "Roboto";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
}


