.main-container {
  display: flex;
  height: 100vh;
  transition: margin-left 0.3s;
}

.main-content {
  flex: 1;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  transition: margin-left 0.3s;
}

.sub-content {
  margin-top: 10px;
  overflow: auto;
}

.full-width-content {
  width: 100%;
}

.date-content {
  color: #006969;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-left: 10px;
}

.userInfo-content {
  color: #4a4a4a;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.middleContent {
  height: 35%;
  justify-content: space-around;
  align-items: flex-start;
  padding-top: 15px;
  padding-left: 10px;
}

.action-content {
  width: 100%;
  height: 50px;
}


.QuestionnaireManagmentFilter {
  display: flex;
  width: 100%;
  padding: 20px 0px;
  justify-content: space-between;
  align-items: center;
}

.HeaderContent {
  color: #006969;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-left:10px
}

.LeftSection {
  display: flex;
  color: #515772;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  gap: 30px;
}

.RightSection {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 0px 20px;
}

.SearchInput {
  border-radius: 15px;
  border: 1px solid #9F9F9F;
  background: #FFF;
  width: 100%;
  height: 25px;
  padding-left: 10px;
}

.DropdownComponent {
  display: flex;
  width: 140px;
  height: 30px;
  align-items: center;
  gap: 10px;
  border-radius: 29px;
  border: 0.5px solid #929395;
  padding-left: 10px;
  padding-right: 10px;
}

.DeleteButton {
  border-radius: 25px !important;
  background: #006969 !important;
  color: #FFF !important;
  height: 30px !important;
  padding: 0px 15px !important;
  border: none !important;
}

/* .DeleteButton {
  border-radius: 25px !important;
  color: #515772 !important;
  border: 0.5px solid #000 !important;
  opacity: 0.35 !important;
  background: #D1D1D1 !important;
  height: 30px !important;
  padding: 0px 15px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
} */

.AddQuestionnaireButton {
  border-radius: 25px !important;
  background: #006969 !important;
  color: #FFF !important;
  height: 30px !important;
  padding: 0px 15px !important;
  border: none !important;
}

.DeleteButtonQuestionnaire {
  display: flex;
  align-items: center;
  justify-content: center;
}

.DeleteIcon {
  margin-right: 8px;
  /* Adjust the margin as needed */
}

.MainCard {
  min-height: 89vh;
  border-radius: 8px;
  box-shadow: 0px 20px 25px 0px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  padding: 16px;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;
  justify-content: space-between;
  border: 0.8px solid var(--neutral-border, #E6E8F0);
}

.MainContainer {
  max-height: 55vh;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;
  justify-content: space-between;
}

.FixedButtons {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 15px;
  background-color: #fff;
  border-top: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
}

.Container {
  min-height: 83vh !important;
}

.FormContainer {
  margin-bottom: 80px;
  width: 50vh;
}
.FormContainer .form-label {
  color: #00FF00 !important; 
}

.QuestionForm {
  padding: 15px;
  overflow-x: auto;
  background-color: #F3F3F3 !important; 
  font-family: "Roboto";
  margin-top: 10px;
}

.QuestionFormPreview {
  padding: 15px;
  overflow-x: auto;
  background-color: transparent !important; 
  font-family: "Roboto";
  margin-top: 10px;
}

.QuestionFormTitle {
  font-size: 18px !important;
  font-weight: 500;
}

.FormButton {
  margin: 0 5px;
}

.ScoreButton {
  display: flex;
  width: 44px;
  height: 44px;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  color: white;
  background-color: #1D5769;
  border: 1px solid #1D5769;
}

.InputControl {
  border: 1px solid #006969;
  border-radius: 6px;
  border-width: 0.5px;
  border-color: #929395;
  background: #FFF;
}

.FormControlNumberText{
  text-align: center;
}

.labelWithArrow {
  display: flex;
  align-items: center;
  padding-left: 15px;
  gap: 10px;
}

.label {
  font-weight: bold !important;
}

.BorderedComponent {
  border: 1px solid #006969 !important ;
  border-radius: 6px !important;
  border-width: 0.5px !important;
  border-color: #929395 !important;
}

.RadioComponent {
  align-items: center !important ;
}
.RadioComponent label {
  margin-right: 8px !important ;
}

.RadioComponent input[type="radio"] {
  border: 2px solid #929395 !important ;
  border-radius: 50% !important ; 
  padding: 5px !important ; 
  margin-right: 0px !important ;
}

.CheckboxComponent {
  align-items: center !important ;
}
.CheckboxComponent label {
  margin-right: 8px !important ;
}

.CheckboxComponent input[type="checkbox"] {
  border: 2px solid #929395 !important ;
  padding: 5px !important ; 
  margin-right: 0px !important ;
}

.Footer {
  display: flex;
}

.dropzone {
  border: 2px dashed #cccccc;
  background-color: #f9f9f9;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.customDropzone {
  color: #333333;
  font-size: 16px;
}

.AddQuestionButton, .AddQuestionLabel {
  font-weight: 700;
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.AddQuestionButton {
  border-radius: 25px !important;
  color: #212241 !important;
  background-color: #B7C0EE !important;
  border: 1px solid #B7C0EE !important;
  margin-top: 20px;
  font-weight: 700;
  stroke: #212241;
}

.AddQuestionLabel {
  color: #1D5769 !important;
  font-weight: 700;
  stroke: #1D5769;
  margin-top: 10px;
  font-size: 14px;
}

.PreviewAnswerLabel {
  margin-bottom: 0px !important;
}

.NumberColumn {
  flex: 0 0 !important;
}

.AnswerLabels {
  overflow: hidden;
  padding-bottom: 10px;
  color: #4F4F4F;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.PreviewQuestionnaire {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
}

.PreviewTitle {
  font-size: 20px !important;
  color: #25282B;
}

.PreviewGroups {
  font-weight: 500;
  color: #858689;
  font-size: 16px !important;
  font-size: 16px;
  display: flex;
  flex-direction: column;
}

.PreviewDescription {
  font-weight: 400;
  color: #25282B;
  font-size: 16px;
}

.SaveButton, .CancelButton {
  display: flex;
  width: 128px;
  height: 40px;
  min-width: 116px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background-color: #F3F3F3;
  color: #00162E;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: 0.64px;
}

.SaveButton {
  border: 1px solid var(--Web-Colors-btn-ol-secondary-default, #00162E);
}

.CancelButton {
  border: none;
}

.SaveButton:hover, .CancelButton:hover {
  background-color: #F3F3F3;
  color: #00162E;
}

.ScrollContainer {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 70%;
}

@media (min-width: 1450px) {
  .ScrollContainer {
      height: 75%;
  }
}
