.Toggle {
    padding: 4px;
    border-radius: 30px;
    display: inline-block;
    border-radius: 25px;
    border: 2px solid var(--Neutral-100, #F3F3F3);
    background: var(--Neutral-100, #F3F3F3);
  }
  
  .Toggle [name="customToggle"] {
    display: none
  }
  
  .Toggle input[type="radio"]+label {
    background: transparent;
    color: #828282;
    padding: 10px 20px;
    border-radius: 25px;
    cursor: pointer;
    user-select: none;
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    letter-spacing: 0.64px;
  }
  
  .Toggle input[type="radio"]:checked+label {
    border-radius: 25px;
    border: 1px solid var(--Neutral-700, #4F4F4F);
    background: #FFF;
    box-shadow: 1px 1px 12px 0px rgba(0, 0, 0, 0.10);
    color: #000000;
  }