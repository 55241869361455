.SecondaryButton, .ModelButton {
    display: flex;
    height: 40px;
    min-width: 116px;
    padding: 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    letter-spacing: 0.64px;
    border-radius: 6px;
}

.SecondaryButton {
    width: 128px; 
    color: #1D5769; 
    border: 1px solid #1D5769;
    background-color: white;
}

.ModelButton {
    background-color: #1D5769;
    color: white;
    border: 1px solid #1D5769;
}