.AddColButton {
    width: 150px;
    height: 30px;
    border-radius: 6px;
    background-color: white;
    border: none;
    color: var(--Web-Colors-btn-text-default, #1D5769);
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    letter-spacing: 0.64px;
    margin-bottom: 20px;
  }
  
.AddColButton:hover, .AddColButton:focus,  .AddColButton:active{
    background-color: white;
    color: var(--Web-Colors-btn-text-default, #1D5769);
    outline: none !important;
   box-shadow: none;
}

.RemoveColButton {
    width: auto;
    height: auto;
    border-radius: 6px;
    background-color: white;
    border: none;
}

.RemoveColButton:hover {
    background-color: white;
    color: var(--Web-Colors-btn-text-default, #1D5769);
}