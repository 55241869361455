.ReportsLabel {
    color: var(--Colors-on-surface, #4F4F4F);
    width: 336px;
    flex-shrink: 0;
    /* Subtitle 1 */
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
}

.ReportsLabel2 {
    color: var(--Neutral-Black, #25282B);

    /* Headline 6 */
    font-family: Roboto;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.03px;
}

.Header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px; /* Smaller gap below the header */
}

.ReportsNumber {
    color: var(--Secondary-400, #30584A);
    text-align: center;
    
    /* Headline 4 */
    font-family: "DM Serif Display";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 125% */
}

.PopupContainer .text {
    align-items: flex-start;
    display: inline-flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 8px;
    position: relative;
}

.PopupContainer {
    display: inline-flex;         
    padding: 28px;                
    flex-direction: column;       
    align-items: flex-start;                         
    border-radius: 16px;         
    background: var(--Colors-surface, #FFF); 
    box-shadow: var(--card-shadow);
    position: relative;            
    top: 50%;                     
    left: 50%;                    
    transform: translate(-50%, -50%);
    z-index: 1050;                
    width: 420px;                         
}

.ButtonContainer {
    align-items: flex-end;
    display: flex;
    flex: 0 0 auto;
    gap: 10px;
    justify-content: flex-end;
    position: relative;
    width: 364px;
}

.ReportsBody2 {
    color: #5E5E5E;
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.04px;
    margin-bottom: 24px;
}

.PopupListItems {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.PopupListItems > div {
    display: flex;
    column-gap: 5px;
    align-items: center;
}

