
.HeaderContent {
    height: 5%;
    margin-bottom: -20px;
}

.MiddleContent {
    height: 35% !important;
    display: flex !important;
    justify-content: space-around !important;
    align-items: flex-start !important;
    padding-left: 10px !important;
}

.GridContent {
    height: 55%;
    background-color: white;
    margin: 5px 10px;
    padding: 0px 10px;
    border-radius: 8px;
    box-shadow: 0px 20px 24.200000762939453px 0px #0000000F;
}

.CardRow {
    display: flex;
    align-items: stretch;
    gap: 10px;
    flex-grow: 1;
}

.CardContent {
    width: 60% !important;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap !important;
    gap: 14px !important;
    padding-top: 34px !important;
    padding-right: 10px;
}