/* Remove background color from DatePicker header */
.react-datepicker__header {
    background-color: transparent !important; /* Remove background color */
    border-bottom: none !important; /* Remove top border */
  }
  
  /* Remove default styles from month name */
  .react-datepicker__current-month {
    color: inherit !important; /* Use inherited text color */
    font-size: inherit !important; /* Use inherited font size */
    padding: 0 !important; /* Remove padding */
  }
  
  /* Remove default styling of the datepicker itself */
  .react-datepicker {
    background-color: transparent !important; /* Remove background color */
  }
  
  /* Remove default styling of navigation buttons */
  .react-datepicker__navigation {
    background: none !important; /* Remove background color */
    color: inherit !important; /* Use inherited text color */
  }
  
  /* Optional: Adjust button styles */
  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    background: none !important;
    color: inherit !important;
  }
  
  /* Optional: Remove border for the selected date */
  .react-datepicker__day--selected {
    border: none !important;
  }

  .due-date-picker {
    border: 1px solid #ccc; 
    border-radius: 4px; 
    padding: 4px; 
    width: 15vw; 
    height: 6vh;
  }
  