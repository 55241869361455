.custom-card {
  height: 94px;
  min-width: 114px;
  max-width: 220px;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.10);
  background-color: #fff;
  align-items: flex-start;
  gap: 16px;
  border: 0.8px solid var(--neutral-border, #E6E8F0);
  font-family: "DM Sans";
  flex: 1 0 0;
}

.selected-card {
  border: 2px solid #0CCBC3;
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.10);
  background-color: #F2FCFB;
  position: relative;
}

.selected-card::after {
  content: "Clear";
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: 12px;
  color: #0CCBC3;
  font-weight: bold;
}

.icon-row {
  height: 30px;
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin: auto;
  justify-content: space-between;
}

.text-row {
  color: var(--neutral-text-subdued, #515772);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  /* max-width: 80%; */
}