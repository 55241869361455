.MainDivFilter {
  display: flex;
  width: 100%;
  padding: 20px 0px;
  justify-content: space-between;
  align-items: center;
}

.HeaderContent {
  color: #006969;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-left:10px
}

.LeftSection {
  display: flex;
  color: #515772;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  gap: 30px;
}

.RightSection {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 0px 20px;
}

.SearchInput {
  border-radius: 15px;
  border: 1px solid #9F9F9F;
  background: #FFF;
  width: 100%;
  height: 30px;
  padding-left: 10px;
}

.DropdownComponent {
  display: flex;
  width: 140px;
  height: 30px;
  align-items: center;
  gap: 10px;
  border-radius: 29px;
  border: 0.5px solid #929395;
  padding-left: 10px;
  padding-right: 10px;
}

.DeleteButton {
  border-radius: 25px !important;
  background: #006969 !important;
  color: #FFF !important;
  height: 30px !important;
  padding: 0px 15px !important;
  border: none !important;
}

/* .DeleteButton {
  border-radius: 25px !important;
  color: #515772 !important;
  border: 0.5px solid #000 !important;
  opacity: 0.35 !important;
  background: #D1D1D1 !important;
  height: 30px !important;
  padding: 0px 15px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
} */

.AddButtonMain {
  border-radius: 25px !important;
  background: #006969 !important;
  color: #FFF !important;
  height: 30px !important;
  padding: 0px 15px !important;
  border: none !important;
}

/* .DeleteButton {
  display: flex;
  align-items: center;
  justify-content: center;
} */

.DeleteIcon {
  margin-right: 8px;
  /* Adjust the margin as needed */
}

.MainCard {
  min-height: 89vh;
  border-radius: 8px;
  box-shadow: 0px 20px 25px 0px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  padding: 16px;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;
  justify-content: space-between;
  border: 0.8px solid var(--neutral-border, #E6E8F0);
}

.MainContainer {
  padding: 16px;
  max-height: 55vh;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;
  justify-content: space-between;
  border: 0.8px solid var(--neutral-border, #E6E8F0);
}

.FixedButtons {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 15px;
  background-color: #fff;
  border-top: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
}

.Container {
  min-height: 83vh !important;
}

.FormContainer {
  /* width: 70vh; */
  overflow-y: scroll;
  height: 90%;
}

.FormContainer .form-label {
  color: #00FF00 !important; 
}

.FormButton {
  margin: 0 5px;
}

.InputControl {
  border: 1px solid #006969;
  border-radius: 6px;
  border-width: 0.5px;
  border-color: #929395;
  background: #FFF;
}

.FormControlNumberText{
  text-align: center;
}

.labelWithArrow {
  display: flex;
  align-items: center;
  padding-left: 15px;
  gap: 10px;
}

.label {
  font-weight: bold !important;
}

.BorderedComponent {
  border: 1px solid #006969 !important ;
  border-radius: 6px !important;
  border-width: 0.5px !important;
  border-color: #929395 !important;
}

.RadioComponent {
  align-items: center !important ;
}
.RadioComponent label {
  margin-right: 8px !important ;
}

.RadioComponent input[type="radio"] {
  border: 2px solid #929395 !important ;
  border-radius: 50% !important ; 
  padding: 5px !important ; 
  margin-right: 0px !important ;
}

.CheckboxComponent {
  align-items: center !important ;
}
.CheckboxComponent label {
  margin-right: 8px !important ;
}

.CheckboxComponent input[type="checkbox"] {
  border: 2px solid #929395 !important ;
  padding: 5px !important ; 
  margin-right: 0px !important ;
}

.Footer {
  display: flex;
}

.dropzone {
  border: 2px dashed #cccccc;
  background-color: #f9f9f9;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.customDropzone {
  color: #333333;
  font-size: 16px;
}

.AddButton {
  border-radius: 8px !important;
  color: #006969 !important;
  background-color: #fff !important;
  border: 1px solid #006969 !important;
}

.NumberColumn {
  flex: 0 0 !important;
}

.FormCheck {
  display: flex;
  column-gap: 10px;
}

