.mainContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.HeaderContent {
  margin: 0 2%;
  font-size: 32px;
  font-weight: 700;
  line-height: 43px;
  letter-spacing: 0em;
  color: #00162E;
  text-align: left;
  font-family: "DM Serif Display";
}

.FilterContainer {
  display: flex;
  flex-direction: column;
  padding: 0px 15px;
  background-color: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0px 20px 25px 0px rgba(0, 0, 0, 0.15);
  height: 86vh;
  padding-top: 15px;
  margin: 0% 2%;
  transition: margin-left 0.3s;
  flex: 1 1;
}


.innerContainer {
  display: flex;
  align-items: center;
  column-gap: 5px;
}

.userDetails {
  display: flex;
  flex-direction: column;
}

.userName, .userNameNew {
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.024px;
}

.userName {
  color: #4A4A4A;
}

.userNameNew {
  color: var(--Neutral-Black, #000);
}

.listMessage, .listMessageNew {
  color: #555;
  color: var(--Neutral-Black, #000);
  font-family: "Roboto";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}

.listMessage {
  color: #4A4A4A;
}

.listMessageNew {
  color: var(--Neutral-Black, #000);
}

.listMessageItalic {
  font-style: italic;
}

.itemTime {
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.04px;
}

.textContainer {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  align-items: center;
}

.additionalText {
  color: #F67F18;
  font-size: 12.29px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.appointmentTimeContainer {
  display: flex;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  background: #006969;
}

.appointmentTime {
  color: #FFF;
  font-size: 12.29px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.PatientMessageListContainer {
  max-height: calc(100% - 100px);
  overflow-y: auto;
}

.PatientMessageContainer {
  width: 100%;
  transition: width 0.3s ease;
  max-height: calc(100% - 500px);
  overflow-y: auto;
}

.PatientMessageContainerSmall {
  width: 60%;
}

.PatientMainContainer {
  display: flex;
  height: 85vh;
}

.PatientMessageContainer {
  flex: 1;
  width: 60%;
  max-height: 74vh;
  min-height: 74vh;
  overflow: auto;
}

.ChatContainer {
  width: 40%;
  max-height: 74vh;
  overflow: auto;
  background-color: #f9f9f9;
  border-left: 1px solid #ddd;
  padding: 10px 10px;
  z-index: 2;
  border-radius: 12px;
  background: var(--Colors-surface, #FFF);
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.10);
}

@media (min-width: 1400px) {
  .ChatContainer {
      max-height: 74vh;
  }
}

.VideoCallModelRight {
  border-left: 1px solid #ddd;
  padding: 10px;
  border-radius: 12px;
}

.VideoCallModelRightSmall {
  position: fixed;
  right: 5px;
  bottom: 5px;
}

.VideoCallModelRightLarge {
  width: 80%;
  position: fixed;
  right: 0px;
  bottom: 0px;
  left: 0%;
}

.unread {
  font-weight: bold;
}


.transparentBackdrop {
  opacity: 0;
}


.ChatMainContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.CloseButton {
  top: 5px;
  right: 5px;
}

.TopSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.TopSection input[type="text"] {
  flex: 1;
  margin-right: 10px;
}

.Username {
  font-weight: bold;
  margin-right: 10px;
  gap: 10px;
}

.UserSelect {
  font-weight: bold;
  margin-right: 10px;
  width: 100%;
  gap: 10px;
}

.Actions {
  display: flex;
  align-items: center;
  gap: 20px;
}

.VideoCallIcon,
.VoiceCallIcon,
.CloseButton {
  margin-left: 10px;
}

.BottomSection {
  margin-top: auto;
  display: flex;
  align-items: center;
  gap: 10px;
}

.BottomSection input[type="text"] {
  flex: 1;
  margin-right: 10px;
}

.ChatDisplayBox {
  display: flex;
  flex-direction: column;
  height: 50vh;
  overflow: auto;
}

.MessageContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 8px;
}

.Message {
  border-radius: 8px;
  padding: 8px 12px;
  max-width: 70%;
}

.SenderBox {
  align-self: flex-end;
  margin-top: 4px;
}

.optionsContainer {
  position: relative;
}

.optionsMenu {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  white-space: nowrap;
  color: var(--Colors-on-menu, #000);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: 0.64px;
  padding: 10px;
}

.optionItem {
  padding: 0px 8px;
  cursor: pointer;
  height: 28px;
  display: flex;
  align-items: center;
}

.optionItem svg {
  margin-right: 10px;
}


.optionItem:hover {
  background-color: #B2C7CE;
  border-radius: 4px;
}

.horizontalDots {
  transform: rotate(90deg);
}

.dotsButton {
  background: none;
  border: none;
  padding: 10px;
  /* Increase padding to make the area around the icon clickable */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -10px;
  /* Counteract the padding to keep layout */
}

.dotsButton:focus {
  outline: none;
}

.modalContainer {
  width: 100%;
  padding-left: 20px;
  transition: width 0.3s ease;
  max-height: calc(100% - 500px);
  overflow-y: auto;
}

.endCallButton {
  position: 'absolute';
  top: '10px';
  right: '10px';
  padding: '10px 20px';
  color: 'white';
  background-color: 'red';
  border: 'none';
  border-radius: '5px';
  cursor: 'pointer';
  opacity: 0.7
}

.ReceiverBox {
  align-self: flex-start;
  margin-top: 4px;
}

.Sender {
  font-size: 9px;
  margin-left: 4px;
}

.IncomingMessage {
  background-color: #CBF3D299;
  color: Neutral/Black;
  align-self: flex-start;
}

.OutgoingMessage {
  background-color: #ECEFF1;

  align-self: flex-end;
}

.IncomingSender {
  justify-content: flex-start;
}

.OutgoingSender {
  justify-content: flex-end;
}

.AttachedFiles {
  display: flex;
  flex-wrap: wrap;
}

.AttachedFile {
  margin-right: 10px;
  white-space: nowrap;
}

.iconContainer {
  position: relative;
  display: inline-block;
}

.initials {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--Colors-text-avatar, #212121);
  text-align: center;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.04px;
}

.AttachmentContainer {
  margin-top: 5px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.Attachment {
  margin-right: 10px;
  display: inline-block;
}

.Attachment a {
  color: #007bff;
  text-decoration: none;
}

.Attachment a:hover {
  text-decoration: underline;
}

.unreadMessageIcon {
  position: absolute;
  bottom: 0;
  right: 0;
  /* transform: translate(50%, 50%); */
}

.joinCallLabel {
  cursor: pointer;
  color: red;
  text-align: right;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.64px;
  margin-left: 10px;
}

.staffSpecialtyContainer {
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  color: var(--Neutral-Black, #000);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.04px;
}
