.notificationSetupContainer {
    display: flex;
    width: 1030px;
    padding: 20px;
    align-items: center;
    border-radius: 16px;
    border: 0.5px solid var(--Colors-ol-card, #D6D6D6);
    background: #FFF;
    box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.10);
    justify-content: space-between;
}

.AddNotificationButton {
    border-radius: 6px;
    border: 1px solid var(--Web-Colors-btn-ol-secondary-default, #00162E);
    background: var(--Web-Colors-btn-bg-secondary-default, #FFF);
    display: flex;
    width: 128px;
    height: 40px;
    min-width: 116px;
    padding: 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: var(--Web-Colors-btn-on-secondary, #00162E);
    text-align: center;
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    letter-spacing: 0.64px;
}

.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modalContent {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1001;
    max-width: 500px;
    width: 90%;
  }

  .viewProgramContent {
      display: flex;
      flex-direction: column;

  }
  .viewProgramContent div{
    color: var(--Colors-on-surface, #4F4F4F);
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.04px;  
}

.viewProgramContentCards {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0;
}