.userOptionsCard {
    position: absolute;
    top: 0;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: auto;
    padding: 10px;
    border-radius: 5px;
    margin-right: 10px;
    margin-top: 50px;
    min-width: 340px;
}

.option {
    padding: 10px;
    cursor: pointer;
    border-top: 1px solid #eee;
    color: var(--Colors-text-card-title, #212121);

    /* Subtitle 2 */
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.024px;

}

.option:first-child {
    border-top: none;
}

.option:hover {
    background-color: #f5f5f5;
}

.formHeader {
    color: var(--Neutral-700, #4F4F4F);
    /* Body 2 */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.04px;
  }

.formContactInfo{
    color: var(--Neutral-Black, #000);
    /* Body 2 */
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.04px;
}

.styledInputContainer {
    position: relative;
    display: flex;
    flex-direction: column;
}

.styledInputContainer:focus-within label,
.styledInputContainer .filled {
    transform: translate(2.5px, 2px) scale(0.7);
    color: #4F4F4F;
    font-weight: 500;
    font-size: 16px !important;
    font-family: "Roboto" !important;
}

/*  common input styles */
.styledInputContainer input, .styledInputSelect, .noteInput {
    font-family: "Roboto" !important;
    font-size: 16px !important;
    font-weight: 400;
    line-height: 24px !important;
    width: 300px;
    border: 0.5px solid var(--Colors-ol-input-default, #686869); 
    border-radius: 6px;
    color: #000;
}

.styledInputContainer:focus-within label,
.styledInputContainer .filled {
    transform: translate(2.5px, 2px) scale(0.7);
    color: #4F4F4F;
    font-weight: 500;
    font-size: 16px !important;
    font-family: "Roboto" !important;
}

.styledInputContainer label {
    position: absolute;
    pointer-events: none;
    transform: translateY(11px) scale(1);
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    padding-left: 12px;
    color: #4F4F4F;
    font-family: "Roboto" !important;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.024px;
    font-size: 16px !important;
}

.styledInputContainer input {
    padding: 12px 10px 0px 10px !important;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    height: 44px;
}

.styledInputSelect {
    height: 44px;
    padding: 12px 10px 0px 7px;
}

.floatingLabel, .floatingLabelDisabled {
    padding: 3px 0 0 12px;
    position: absolute;
    font-size: 11px !important;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    color: #4F4F4F;
}

.floatingLabelDisabled {
    color: hsl(0, 0%, 60%) !important;
}

.logoutButton {
    display: flex;
    height: 40px;
    padding: 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 6px;
    background: var(--Web-Colors-btn-bg-primary-default, #1D5769);
    color: white;
    width: 100%;
    border: none;
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    letter-spacing: 0.64px;
    margin-top: 10px;
}

.version {
    color: var(--Primary-500, #00162E);
    font-family: "Roboto";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    text-decoration-line: underline;
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 20px 0;
}

