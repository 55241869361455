.patient-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
}

.header-row {
  display: flex;
  width:100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.child {
  padding-bottom: 20px;
  cursor: move;
}

.child:hover {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  transition: box-shadow 0.3s ease;
}

.patient-photo {
  width: 80px;
  height: 60px;
  border-radius: 50%;
}

.right-section {
  display: flex;
  padding-left: 20px;
  column-gap: 10px;
  align-items: center;
}

.botton-header {
  display: flex;
  align-items: center;
  padding: 10px;
  
}

.careTeamOptions {
  padding: 0px 8px;
  cursor: pointer;
  height: 28px;
  display: flex;
  align-items: center;
}

.careTeamOptions svg {
  margin-right: 10px;
}


.careTeamOptions:hover {
  background-color: #D6D6D6;
  border-radius: 4px;
}

.patient-name {
  color: var(--Colors-on-background, #373737);
  font-family: "DM Serif Display";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px; /* 125% */
}

.patient-details {
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
}

.patient-details span {
  padding: 0 5px;
  color: var(--Neutral-900, #373737);
  font-family: "Roboto";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
  border-right: 1px solid var(--Neutral-400, #9D9D9E);
}


.action-button {
  margin-right: 5px;
}

.action-button:last-child {
  margin-right: 0;
}

.patient-condition-button {
  height: 35px;
  border-radius: 35px;
  background: #515772;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
}

.patient-condition-text {
  color: #FFF;
  padding: 8px 16px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.header-button {
  display: flex;
  height: 40px;
  min-width: 116px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: var(--Web-Colors-btn-bg-primary-default, #1D5769);
  color: var(--Web-Colors-btn-on-primary, #FFF);
  text-align: center;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: 0.64px;
  border: none;
}

.header-button:hover {
  background: var(--Web-Colors-btn-bg-primary-default, #1D5769);
}

.bottom-border {
  border-bottom: 1px solid #9D9D9E;
}

.header-button-rpm {
  margin-left: 10px;
  height: 35px;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  color: var(--Colors-on-background, #373737);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.024px;
}

.header-button-rpm-box {
  margin-left: 10px;
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 4px;
  border: 0.5px solid var(--Colors-ol-input-default, #686869);
  background: var(--Colors-bg-input-default, #FFF);
  color: var(--Colors-text-input-default, #000);
  font-family: "Roboto";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}

.pause-button-icon {
  margin-right: 5px; 
}

.bottom-section-row {
  margin-left: 10px;
  display: inline-flex;
  justify-content: space-between;
  vertical-align: middle;
  width: 100%;
}

.bottom-section {
  margin-left: 10px;
  display: inline-flex;
  justify-content: space-between;
  vertical-align: middle;
  height: 35px;
}

.bottom-section-label {
  display: flex;
  align-items: center;
  color: #515772;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.bottom-section-label svg {
  margin-right: 5px; 
  vertical-align: middle;
}

.bottom-row {
  margin-bottom: 0px;
  font-weight: bold;
  color: #4A4A4A;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  justify-content: space-between;
  line-height: normal;
}

.BodyContainer {
  border-radius: 8px;
  background: #FFF;
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.10);
  display: flex;
  padding: 5px 20px 5px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  min-height: 100vh;
  height: auto; /* Allow the container to grow */
  overflow-y: auto; /* Add scrolling if content exceeds viewport height */
}

@media (min-width: 1450px) {
  .BodyContainer {
    min-height: 100vh; /* Changed from height to min-height */
  }
}

.PatientDetailsList {
  display: flex;
  margin: 0 auto;
  width: 100%;
  font-family: "Roboto";
  color: #66696B;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  border-bottom: 0.5px solid var(--Neutral-300, #B9B9B9);
  justify-content: flex-start;
  letter-spacing: 0.64px;
}

.PatientDetailsList > div {
  display: flex;
  padding: 0px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.PatientDetailsList > div:hover {
  background: rgba(203, 243, 210, 0.30);
  /* border-bottom: 2px solid #006969; */
}

.Selected {
  border-bottom: 3px solid #006969;
  background: rgba(203, 243, 210, 0.30);
  padding: 0px 20px 0px 20px !important;
}

.Selected > p{
  font-weight: 700 !important;
  font-family: "Poppins";
  font-size: 15px;
  color: #000000;
}
/* View styles */

.ViewContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  width: 100%;
  /* overflow-y: scroll; */
}

.GraphPeriodButtons {
  display: flex;
  column-gap: 5px;
  align-items: center;
}

.GraphPeriodButtons > div{
  display: flex;
  width: 92px;
  height: 30px;
  padding: 10px 10px 10px 10px;
  align-items: center;
  gap: 10px;
  border-radius: 22px;
  background-color: #D6D6D6;
  color: var(--Colors-text-actionchip-default, #212121);
  text-align: center;
  font-family: "Roboto";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 150% */
  letter-spacing: 0.64px;
  cursor: pointer;
  justify-content: center;
}

.TimePeriodSelected {
  border: 0.5px solid var(--Colors-ol-actionchip-selected, #1D5769);
  background: var(--Colors-bg-actionchip-selected, #B2C7CE) !important;
  justify-content: flex-start !important;
}

.DischargeDropdownMenu {
  background-color: #FFFFFF;
  border: 0.5px solid #CFCFCF;
  box-shadow: 1px 1px 15px 0px #0000001A;
}

.DischargeDropdownItem {
  color: var(--Colors-on-menu, #000);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.64px;
}

.DischargeDropdownItem:hover {
  background-color: #B2C7CE;  
}


/* CareTeamListItem styles */

.CareTeamListItem {
  display: flex;
  height: 71px;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  border-bottom: 0.25px solid #949494;
  color: var(--Neutral-800, #373737);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  letter-spacing: 0.024px;
  width: 70%;
  position: relative;
}

.BorderedItem {
  border-right: 0.5px solid var(--Neutral-400, #9D9D9E);
  border-left: 0.5px solid var(--Neutral-400, #9D9D9E);
  padding: 0 20px;
}

.SpecialtyContainer {
  display: flex;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
}

.optionsMenu {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  white-space: nowrap;
  color: var(--Colors-on-menu, #000);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: 0.64px;
  padding: 10px;
}

.optionItem {
  padding: 0px 8px;
  cursor: pointer;
  height: 28px;
  display: flex;
  align-items: center;
}

.CareTeamOptionsContainer {
  display: inline-flex;
  padding: 20px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-radius: 12px;
  border: 0.5px solid #CFCFCF;
  background: #FFF;
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.10);
  position: absolute;
  z-index: 30;
  width: auto;
  color: #000;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 138.462% */
  letter-spacing: 0.2px;
  cursor: pointer;
  white-space: nowrap;

}

.NewNoteSection {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid #B9B9B9;
}


.NewNote {
  display: flex;
  padding: 10px 12px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 6px;
  border: 0.5px solid var(--Colors-ol-input-default, #686869);
  background: var(--Colors-bg-input-default, #FFF);
  color: var(--Colors-text-label-default, #4F4F4F);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.024px;
  resize: both;
  overflow: auto;
  max-width: 740px;
  min-width: 100px;
  max-height: 500px;
  min-height: 120px;
}


.SaveDraftButton, .NewNoteButton {
  display: flex;
  height: 40px;
  min-width: 116px;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 25px;
  border: none;
  width: 157px;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: 0.64px;
}

.NewNoteButton:hover, .NewNoteButton:disabled, .NewNoteButton {
  background: #B7C0EE;
  color: #212241;
}

.SaveDraftButton:hover, .SaveDraftButton:disabled, .SaveDraftButton {
  background: white;
  color: #363E6B;
  border: 1px solid var(--Web-Colors-btn-ol-tertiary-outlined-default, #363E6B);
}

.CancelDraftButton:hover, .CancelDraftButton:disabled, .CancelDraftButton {
  background: white;
  color: #363E6B;
  border: none;
}

.CancelDraftButton {
  display: flex;
  height: 40px;
  width: auto;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 25px;
  border: none;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: 0.64px;
}

.NotesTitle {
  color: var(--Colors-on-background, #373737);
  font-family: "Roboto";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 140% */
  letter-spacing: 0.03px;
}

.SecondaryButton, .ModelButton {
  display: flex;
  height: 40px;
  min-width: 128px !important;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: 0.64px;
  border-radius: 6px;
  flex: 0 0;
}

.SecondaryButton {
  color: #1D5769; 
  border: 1px solid #1D5769;
  background-color: white;
}

.ModelButton {
  background-color: #1D5769;
  color: white;
  border: 1px solid #1D5769;
}

.GroupsDisplayList {
  padding-left: 0;
}

.group-list-name {
  column-gap: 10px;
  display: flex;
  align-items: center;
  height: 52px;
}

.group-list-item li > span, .group-list-name span {
  color: var(--Colors-text-card-title, #212121);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.04px;
  list-style: none;
  height: 52px;
  display: flex;
  align-items: center;
}

.group-list-item li {
  list-style: none;
}


/* chat video popup styles */
.PopupContainer {
  width: 460.9px;
  height: 653px;
  background-color: #f9f9f9;
  border-left: 1px solid #ddd;
  z-index: 2;
  border-radius: 14px;
  background: var(--Colors-surface, #FFF);
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.10);
}

.PopupMain {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.NavBar {
  display: flex;
  justify-content: space-between;
  display: flex;
  height: 62px;
  padding: 0px 10px;
  flex-shrink: 0;
  align-self: stretch;
  align-items: flex-end;
  border-radius: 14px 14px 0px 0px;
  background: var(--Neutral-100, #F3F3F3);  
}

.NavTabs {
  display: flex;
  align-items: center;
}

.NavTabs div {
  display: flex;
  width: 60px;
  height: 43px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  color: var(--Neutral-900, #212121);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.024px;
}

.selectedNav {
  background-color: white;
  border-radius: 8px 8px 0px 0px;
  width: auto !important;
  padding: 0px 10px;
  gap: 8px;
  border-top: 0.5px solid var(--Neutral-400, #9D9D9E);
  border-left: 0.5px solid var(--Neutral-400, #9D9D9E);
}

.CalendarTitle {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 12px 10px;
  align-items: center;
  border-radius: 4px;
  background: var(--Neutral-100, #F3F3F3);
}

.CalendarAlertsTitle {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 12px 10px;
  align-items: center;
  border-radius: 4px;
  background: var(--Neutral-100, #F3F3F3);
  color: var(--DMV-Black, #23262F);
  text-align: center;
  font-family: "Roboto";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 140% */
  letter-spacing: 0.03px;
}

.AlertContainer {
  display: flex;
  border-bottom: 0.5px solid #B9B9B9;
  padding: 10px;
  align-items: center;
  gap: 27px;
  align-self: stretch;
}

.popupCalendarContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.calendarHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: flex;
  width: 100%;
  padding: 12px 10px;
  border-radius: 4px;
  background: var(--Neutral-100, #F3F3F3);
}

.calendarNavigation {
  display: flex;
  column-gap: 10px;
}

.calendarNavigation button{
  background: transparent;
  border: none;
}

.dayOfWeekContainer {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 6px;
  place-items: center;
}

.dayLabel {
  display: flex;
  width: 44.699px;
  height: 27.522px;
  padding: 4.375px;
  align-items: center;
  gap: 10.938px;
  color: #2B2B36;
  justify-content: center;
  text-align: center;
  font-family: "Poppins";
  font-size: 10.938px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.daysContainer {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 15px;
  place-items: center;
  position: relative;
}

.day {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dayNumber {
  color: var(--Neutral-Black, #000);
  text-align: center;
  font-family: Poppins;
  font-size: 15.313px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  display: flex;
  width: 33.525px;
  height: 28.745px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  align-items: center; /* Center alignment */
}

.currentMonth {
  color: #2c3e50;
}

.otherMonth {
  color: #bdc3c7;
}

.today {
  background-color: rgb(29, 87, 105);
  border-radius: 4px;
  color: white;
}

.notToday:hover, .selectedDay {
  background-color: #B2C7CE;
  border-radius: 4px;
}

.calendarIcons {
  display: flex;
  align-items: center;
  column-gap: 5px;
  justify-content: center;
  position: absolute;
  padding-top: 30px;
}

.scrollableContainer {
  height: calc(100vh - 120px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 20px; /* Increased gap between charts */
}

.child {
  padding-bottom: 0; /* Remove bottom padding */
  cursor: move;
  transition: box-shadow 0.3s ease;
}

.child:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

/* New styles for the chart container */
.chartContainer {
  border: 1px solid transparent;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease;
}

.chartContainer:hover {
  border-color: #B2C7CE;
}

/* Style for the drag handle */
.dragHandle {
  height: 10px;
  background-color: #f0f0f0;
  cursor: move;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dragHandle::before {
  content: '......';
  color: #999;
  letter-spacing: 2px;
}

.ChatContainer {
  width: 30%;
  height: 600px;
  position: absolute;
  background-color: #f9f9f9;
  /* border-left: 1px solid #ddd; */
  padding: 10px 10px;
  /* display: flex; */
  z-index: 100;
  border-radius: 12px;
  background: var(--Colors-surface, #FFF);
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.10);
  bottom: 20px;
  right: 30px;

}

.profileEditIcon {
  position: absolute;
  bottom: -8px;
  right: -7px;
  cursor: pointer;
}

.headerProfilePicture {
  position: relative;
  display: inline-block;
  height: 45px;
}

.headerProfilePicture .profileEditIcon {
  visibility: hidden;
}

.headerProfilePicture:hover .profileEditIcon {
  visibility: visible;
}

