  .userDetails {
    display: flex;
    flex-direction: column;
  }
  
  .userName {
    font-weight: bold;
  }
  
  .timestamp {
    color: #555;
  }
  
  .textContainer {
    display: flex;
    justify-content: space-between;
    gap: 5px;
    align-items: center;
  }
  
  .ChatContainer {
    width: 40%;
    max-height: 100%;
    overflow: auto;
    background-color: #f9f9f9;
    border-left: 1px solid #ddd;
    padding: 10px 10px;
    z-index: 2;
    border-radius: 12px;
    background: var(--Colors-surface, #FFF);
    box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.10);
  }

  .VideoCallContainer, .VideoCallContainerExpanded,  .VideoCallContainerMinimized {
    overflow: hidden;
    background-color: #f9f9f9;
    border-left: 1px solid #ddd;
    padding: 10px 10px;
    z-index: 2;
    border-radius: 12px;
    background: var(--Colors-surface, #FFF);
    box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .VideoCallContainer {
    width: 30vw;
    height: 80vh;
  }

  .VideoCallContainerMinimized {
    width: 20vw;
    height: 35vh;
  }

  .VideoCallContainerExpanded {
    width: 80vw;
    height: 90vh;
  }

  .VideoCallTopSection {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 30px;
  }

  .VideoCallMiddleSection {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .VideoCallButtonSection {
    display: inline;
    justify-content: center;
    align-items: center;
    border-top: 0.5px solid #B9B9B9;
    padding: 10px 0;
  }
  
  /* .VideoCallModelRight {
    border-left: 1px solid #ddd;
    padding: 10px;
    border-radius: 12px;
  }
  
  .VideoCallModelRightSmall {
    position: fixed;
    right: 5px;
    bottom: 0px;
    width: 40%;
  }

   .VideoCallModelRightSmall .modal-content {
    height: 100%;
  }
  
  .VideoCallModelRightLarge {
    height: 70vh;
    width: 80%;
    top: 20px;
    right: 10%;
    bottom: 20px;
    left: 10%;
  } */
  
  .unread {
    font-weight: bold;
  }
  
  
  .transparentBackdrop {
    opacity: 1;
  }
  
  
  .ChatMainContainer {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .CloseButton {
    top: 5px;
    right: 5px;
  }
  
  .TopSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .TopSection input[type="text"] {
    flex: 1;
    margin-right: 10px;
  }
  
  .Username {
    font-weight: bold;
    margin-right: 10px;
    display: flex;
    align-items: center;
    column-gap: 10px;
  }
  
  .UserSelect {
    font-weight: bold;
    margin-right: 10px;
    width: 100%;
    gap: 10px;
  }
  
  .Actions {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .Actions button {
    background-color: white;
    border: none;
    padding: 5px;
    display: flex;
    align-items: center;
  }
  
  .VideoCallIcon,
  .VoiceCallIcon,
  .CloseButton {
    margin-left: 10px;
  }
  
  .BottomSection {
    /* margin-top: auto; */
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .BottomSection input[type="text"] {
    flex: 1;
    margin-right: 10px;
  }
  
  .ChatDisplayBox {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
  }
  
  .MessageContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 8px;
  }
  
  .Message {
    border-radius: 8px;
    padding: 8px 12px;
    max-width: 70%;
    color: var(--Neutral-Black, #000);
    font-family: "Roboto";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.04px;
  }
  
  .SenderBox {
    align-self: flex-end;
    margin-top: 4px;
  }

  .expandIcon {
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .endCallButton {
    display: flex;
    width: 52px;
    height: 36px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 6px;
    border: none;
    background: var(--Web-Colors-btn-bg-primary-destructive, #9F1919);
  }
  
  .ReceiverBox {
    align-self: flex-start;
    margin-top: 4px;
  }
  
  .Sender {
    font-size: 15px;
    margin-left: 4px;
  }
  
  .IncomingMessage {
    background-color: #CBF3D299;
    color: Neutral/Black;
    align-self: flex-start;
  }
  
  .OutgoingMessage {
    background-color: #ECEFF1;
  
    align-self: flex-end;
  }
  
  .AttachedFiles {
    display: flex;
    flex-wrap: wrap;
  }
  
  .AttachedFile {
    margin-right: 10px;
    white-space: nowrap;
  }
  
  .iconSolo {
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    position: absolute;
  }

  .iconContainer {
    position: relative;
    display: inline-block;
  }

  .chatName {
    color: var(--Colors-on-surface, #4F4F4F);

    /* Subtitle 1 */
    font-family: "Roboto";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
  }
  
  .initials {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .initialsCall {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--Colors-text-avatar, #212121);
    text-align: center;
    font-family: "DM Serif Display";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 125% */
  }
  
  .AttachmentContainer {
    margin-top: 5px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .Attachment {
    margin-right: 10px;
    display: inline-block;
  }
  
  .Attachment a {
    color: #007bff;
    text-decoration: none;
  }

  .Attachment img {
    max-width: 50%;      
    max-height: 400px;   
    object-fit: contain; 
    cursor: pointer; 
}
  
  .Attachment a:hover {
    text-decoration: underline;
  }

  .muteButton {
    background-color: white;
    border: none;
  }

  .muteButton:hover {
    background-color: white;
  }

  .VideoCallContainerDiv {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    pointer-events: auto;
    /* background-clip: padding-box;
    border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
    border-radius: var(--bs-modal-border-radius); */
    outline: 0;
  }

  .StartCallButton, .CancelCallButton {
    display: flex;
    height: 40px;
    min-width: 116px;
    padding: 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    letter-spacing: 0.64px;
    border-radius: 6px;
  }

  .CancelCallButton, .CancelCallButton:hover {
    color: #00162E;
    border: 1px solid var(--Web-Colors-btn-ol-secondary-default, #00162E);
    background: var(--Web-Colors-btn-bg-secondary-default, #FFF);
  }

  .StartCallButton, .StartCallButton:hover {
    color: white;
    border: 1px solid #1D5769;
    background: var(--Web-Colors-btn-bg-primary-default, #1D5769);
  }

  .StartCallButton:disabled {
    background: #D6D6D6;
    border: 1px solid #D6D6D6;
  }

  .videoElement {
    transition: all 0.3s ease;
    object-fit: contain; /* Maintains aspect ratio and fits within the container */
    width: auto; /* Allows the video to scale properly */
    height: 100%; /* Keeps the full height */
    max-height: 100%;
    max-width: 100%;
  }



.localVideoTopRight, .localVideoTopRightExpanded {
  position: absolute;
  object-fit: cover;
  z-index: 2;
  border: 2px solid white;
  border-radius: 8px;
  right: 10px;
  top: 10px;
}

.localVideoTopRight {
  width: 35%;
}

.localVideoTopRightExpanded {
  width: 25%;
}



.remoteVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: flex; 
  justify-content: center;
  align-items: center;
}

.waitingMessage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #212121;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  width: 100%;
}

.initialsPlaceholderTest {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 2px solid white;
  border-radius: 8px;
}

.initialsTest {
  position: absolute;
  color: #212121;
  font-weight: normal;
  font-size: 24px;
  font-family: "DM Serif Display";
}


.sendButton, .sendButton:hover {
  background-color: #1D5769;
  border: none;
}
  