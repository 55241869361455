.InputDropdownContainer, .InputDropdownContainerPadded {
    height: 44px;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 0.5px solid var(--Colors-ol-input-default, #686869);
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-family: "Roboto" !important;
    font-weight: 400;
    line-height: 24px !important;
    width: 300px;
    border: 0.5px solid var(--Colors-ol-input-default, #686869); 
    border-radius: 6px;
    background-color: #FFFFFF;
    color: #000;
}

.InputDropdownContainer {
    padding: 10px 12px;
}

.InputDropdownContainerPadded {
    padding: 15px 3px 5px 12px;
}

.MultiSelectDropdownListContainer {
    display: flex;
    width: auto;
    padding: 20px 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    background-color: white;
    position: absolute;
    border-radius: 12px;
    border: 0.5px solid #CFCFCF;
    z-index: 3;
    box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.10);
    max-height: 300px;
    overflow-y: scroll;
}

.MultiSelectDropdownLabel, .MultiSelectDropdownLabelActive, 
.MultiSelectDropdownLabel:hover {
    color: #000000;
    font-family: "Roboto";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    letter-spacing: 0.64px;
    display: flex;
    column-gap: 5px;
    width: 100%;
    padding-left: 10px;
    white-space: nowrap;
}

.MultiSelectDropdownLabelActive {
    background-color: #006969;
    color: white;
}

.MultiSelectDropdownLabel:hover{
    background-color: #B2C7CE;
}

.Label {
    width: 270px;
    text-overflow: ellipsis;
    overflow: hidden;
    flex-wrap: nowrap;
    height: 21px;
}

.SearchInput {
    border-radius: 8px;
    border: 1px solid var(--Colors-ol-input-default, #686869);
    padding: 5px 10px;
    width: 100%;
}