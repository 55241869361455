.PopupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.PopupContainer {
    position: absolute;
    width: 320px;
    min-height: 211px;
    height: auto;
    background-color: #FFFFFF;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 6px;
    padding: 10px 28px 20px 28px;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    gap: 20px;
}

.XOut {
    align-self: end;
    cursor: pointer;
    height: 5px;
}

.PopupTitle {
    color: #363636;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.PopupMessage {
    color: #2B2C2E;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.1px;
}

.CancelButton, .ActionButton {
    display: flex;
    width: 125px;
    height: 35px;
    min-width: 100px;
    padding: 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
}

.CancelButton {
    border: 1px solid var(--Web-Colors-btn-ol-secondary-default, #00162E);
    background: var(--Web-Colors-btn-bg-secondary-default, #FFF);
}

.ActionButton {
    color: white;
    border: 1px solid var(--Web-Colors-btn-ol-secondary-default, #1D5769);
    background: var(--Web-Colors-btn-bg-primary-default, #1D5769);
}

.InitialsIcon {
    position: absolute;
    margin-left: 15px;
    color: var(--Neutral-900, #212121);
    text-align: center;
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.04px;
}