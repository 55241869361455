.Heading {
    color: #000;
    text-align: center;
    font-family: 'Roboto';
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
}

.NoWrap {
    white-space: nowrap;
}

.AppTourButton {
    height: 40px;
    min-width: 116px;
    border-radius: 6px;
    background: #1D5769;
    text-align: center;
    color: white;
    justify-content: center;
    align-items: center;
    border: 1px solid #1D5769;
    line-height: 24px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.64px;
    font-style: normal;
}

.AppTourButton:hover {
    background: #1D5769;
}

.ModelHeader {
    border: none;
}

.ModelFooter {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: none;
}


.NextButton,
.NextButton:hover,
.NextButton:focus {
    flex: 1;
    margin: 0 5px;
    height: 40px;
    min-width: 116px;
    border-radius: 6px;
    background: #1D5769;
    text-align: center;
    color: white;
    justify-content: center;
    align-items: center;
    border: 1px solid #1D5769;
    line-height: 24px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.64px;
    font-style: normal;
}

.NextButton:active {
    background-color: #1D5769 !important;
    color: white !important;
    border: 1px solid #1D5769 !important;
}

.NextButton:disabled {
    background-color: #D6D6D6;
    border: 1px solid #D6D6D6;
}


.BackButton,
.BackButton:hover,
.BackButton:focus {
    background-color: white;
    color: #1D5769;
    border: 1px solid #1D5769;
    flex: 1;
    margin: 0 5px;
    height: 40px;
    min-width: 116px;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    line-height: 24px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.64px;
    font-style: normal;
    transition: background-color 0.3s, color 0.3s;
}

.BackButton:active {
    background-color: white !important;
    color: #1D5769 !important;
    border: 1px solid #1D5769 !important;
}

.SquareStyle{
    width: '150px';
    height: '150px'; 
    left: '-200px';
    display: 'flex';
    justify-content: 'center';
    align-items: 'center';
    background-color: '#f0f0f0'; 
    border-radius: '8px';
}



