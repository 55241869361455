.CallNotificationContainer {
    position: absolute;
    z-index: 999;
    width: auto;
    right: 0;
    margin-right: 80px;
    margin-top: 50px;
}

.CallNotificationStyling {
    background-color: white;
    border-radius: 15px;
    border: 0.5px solid var(--Neutral-400, #9D9D9E);
    box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.10);
}

.NotificationHeading {
    color: #000;
    font-family: "Roboto";
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding-top: 10px;
}

.acceptCall, .rejectCall {
    display: flex;
    height: 40px;
    min-width: 116px;
    padding: 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: white;
    text-align: center;
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    letter-spacing: 0.64px;
    border-radius: 6px;
    border: none;
}

.InitialsIcon {
  z-index: 2;
  justify-content: center;
  display: flex;
  color: var(--Colors-text-avatar, #212121);
    text-align: center;

    /* Headline 4 */
    font-family: "DM Serif Display";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 125% */
  position: absolute;
  width: 100%;
}

.acceptCall, .acceptCall:hover {
    background: var(--Web-Colors-btn-bg-primary-default, #1D5769);
}

.rejectCall, .rejectCall:hover {
    background: var(--Web-Colors-btn-bg-primary-destructive, #9F1919);
}

