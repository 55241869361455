/* PatientEnrollmentGridOptionCard.module.css */

.option-container {
    width: 200px;
    height: auto;
    border-radius: 12px;
    border: 0.5px solid #CFCFCF;
    background: #FFF;
    box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.10);
    position: absolute;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px;
    position: absolute;
    transform: translateX(-60%);
}

.option-row {
    display: flex;
    column-gap: 10px;
    height: 28px;
    padding-left: 8px;
    align-items: center;
    align-self: stretch;
    cursor: pointer;
}

.option-row:hover {
    background-color: #B2C7CE;
}

.option-label {
    color: var(--Neutral-Black, #000);
    font-family: "Lato";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px; /* 261.538% */
    letter-spacing: 0.2px;
}