.mainContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border: none;
  margin-bottom: 6px;
  border-radius: 15px;
  background: #FFF;
  height: 63px;
  cursor: pointer;
  /* box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.10); */
}

.patientAppointmentContainer {
  width: 100%;
  height: 100%;
}

.noAppointments {
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 10px;
  border-radius: 8px;
}

.innerContainer {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 10px;
  position: relative;
  display: inline-block;
}

.userDetails {
  display: flex;
  flex-direction: column;
}

.userName {
  font-family: "DM Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 122.222% */
}

.timestamp {
  color: #555;
  font-size: 13px;
}

.textContainer {
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
}

.additionalText {
  font-size: 12.29px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.appointmentTimeContainer {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
}

.appointmentTime {
  font-family: "Poppins";
  font-size: 12.29px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 !important;
}

.initials {
  color: #000;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}