.hello {
    color: red;
}

.device-inputs {
    padding-top: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: auto;
}

.device-input {
    height: 44px;
    border-radius: 6px;
    border: 0.5px solid #929395;
    width: 123px;
    flex-shrink: 0; 
    padding: 10px 12px;
}

.add-another-button {
    width: 200px;
    border-radius: 6px;
    height: 40px;
    background-color: white;
    color: #006969;
    border: 1px solid #006969;
}

.device-selection-container {
    display: flex;
    align-items: center;
}

.trash-icon {
    color: black;
    padding-left: 10px;
    top: 50%;
}

.device-row {
    padding-left: 0px !important;
}