.bell-notification {
    position: absolute;
  }
  
  .icon-container {
    width: 26px;
    height: 26px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  .unread-dot {
    position: absolute;
    top: 0;
    right: 0;
    width: 8px;
    height: 8px;
    background-color: red;
    border-radius: 50%;
  }
  
  .notifications-dropdown {
    position: absolute;
    top: 20px;
    right: 30px;
    width: 500px;
    height: 600px;
    overflow-y: auto;
    background: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
  }
  
  .notifications-header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .notifications-title {
    font-size: 22px;
    font-weight: bold;
  }
  
  .mark-all-as-read {
    font-size: 14px;
    color: #568620;
    cursor: pointer;
  }
  
  .notification-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .notification-time {
    font-size: 12px;
    color: #888;
  }
  
  .no-notifications {
    text-align: center;
    padding: 20px;
    flex-grow: 1;
  }
  
  .no-notifications img {
    width: 150px;
    height: 100px;
    margin-bottom: 10px;
  }
  
  .no-notifications div {
    margin-top: 10px;
  }
  
  .toggle-container {
    display: flex;
    align-items: center;
    padding: 10px;
  }
  
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 24px;
    margin-right: 10px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #a8a8a8;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
  }
  
  input:checked + .slider {
    background-color: #568620;
  }
  
  input:checked + .slider:before {
    transform: translateX(36px);
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .slider::after {
    content: "Off";
    color: white;
    display: block;
    position: absolute;
    top: 50%;
    right: 2px;
    transform: translate(-50%, -50%);
    font-size: 12px;
  }

  input:checked + .slider::after {
    content: "On";
    right: 20px;
  }