.MainContentAccounts {
    background-color: transparent;
    width: 96%;
    margin: 0 2%;
    display: flex;
    flex-direction: column;
    transition: margin-left 0.3s;
}

.MiddleContentAccounts, .MiddleContentAccountsAdd {
    display: flex;
    flex-direction: column;
    padding: 0px 15px;
    background-color: #FFFFFF;
    justify-content: space-between;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0px 20px 24.200000762939453px 0px #0000000F;
}

.MiddleContentAccountsAdd {
    height: 85vh;
}

.AccountsTitle {
    font-size: 32px;
    font-weight: 700;
    line-height: 43px;
    letter-spacing: 0em;
    color: #00162E;
    text-align: left;
    font-family: "DM Serif Display";
}

.HeaderFilterMenu {
    padding: 20px 12px;
    margin-top: 10px;
    width: 132px;
    height: 228px;
    background-color: white;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    border: 0.5px solid #CFCFCF;
    box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.10);
    position: absolute;
    z-index: 3;
}

.HeaderFilterItem {
    color: #3B3E40;
    font-family: "Lato";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.2px;
}

.SelectDropdown {
    width: 140px;
    height: 36px;
    padding-left: 4px;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 0.5px solid #929395;
    padding: 13px 0 0 5px;
}

.FloatingLabel {
    color: #929395;
    font-family: "Lato";
    font-weight: 400;
    line-height: 16px;
    padding: 3px 0 0 10px;
    position: absolute;
    font-size: 12px;
}



.SearchCreateContent {
    display: flex;
    margin: 15px 0px 15px 0px;
    gap: 5px;
    justify-content: space-between;
}


.RightSearchContent{
    display: flex;
    align-items: center;
    column-gap: 7px;
    margin-left: auto;
}

.Search {
    width: 250px;
    height: 36px;
    text-align: left;
    text-indent: 15px;
    font-weight: 500;
    font-size: 14px;
    padding-left: 20px;
    border: 1px solid #9F9F9F;
    border-radius: 8px;
}

.SearchIcon {
    position: absolute;
    padding-left: 10px;
}

.Search::placeholder{
    color: #D9D9D9;
}

/* Dropdown styles */
.CustomDropdown, .CustomDropdown:hover, .CustomDropdown:focus {
    background-color: #006969;
    height: 36px;
    font-size: 12px;
    font-weight: 700;
    border-radius: 8px;
}

.CustomDropdown:hover, .CustomDropdown:focus, .CustomDropdown:active {
    background-color: #006969;
}

.DropdownMenu {
    background-color: #FFFFFF;
    border: 0.5px solid #CFCFCF;
    box-shadow: 1px 1px 15px 0px #0000001A;
}

.DropdownItem {
    font-size: 13px;
}

.DropdownItem:hover {
    background-color: #E8F5F5;
}

.DeleteButton {
    border: 0.5px solid #000000;
    background-color: white;
}

.DeleteButton, .AddButton, .DeleteButtonDisabled {
    width: 109px;
    height: 36px;
    border-radius: 8px;
    font-size: 12px !important;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
}

.DeleteButtonDisabled{
    border: 0.5px solid #000;
    opacity: 0.35;
    background-color: #D1D1D1;
    cursor: not-allowed !important;
}

.AddButton {
    border: 0.5px solid #1D5769;
    background-color: #1D5769;
    color: white;
}

.DeleteButton:hover{
    color: black;
}

.ContentTitle {
    padding-top: 10px;
    font-size: 32px;
    font-weight: 700;
    line-height: 37px; 
    color: #4F4F4F;
    font-family: "DM Serif Display";
}

.CreateButton, .BackButton, .CancelButton {
    border: none;
    font-family: 'Lato';
    font-size: 14px !important;
    font-weight: 700;
    width: 128px;
    height: 35px;
    border-radius: 6px;
}

.CreateButton {
    background-color: #1D5769;
    color: white;
}

.BackButton {
    background-color: white;
    color: #1D5769;
    border: 1px solid #1D5769;
}

.CancelButton {
    background-color: white;
    color: #BEBFBF;
    border: 1px solid #BEBFBF;
}

.ButtonContainer {
display: flex;
padding: 12px 24px;
flex-direction: column;
align-items: center;
gap: 10px;
}

.LoginButton {
    height: 40px;
    min-width: 116px;
    border-radius: 6px;
    background: #1D5769;
    text-align: center;
    color: white;
    justify-content: center;
    align-items: center;
    border: 1px solid #1D5769;
    line-height: 24px; /* 150% */
    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.64px;
    font-style: normal;
}

.LoginButton:hover {
    background: #1D5769;
}

.SetPasswordButton {
    height: 40px;
    min-width: 116px;
    border-radius: 6px;
    width: 300px;
    background: #1D5769;
    color: white;
    justify-content: center;
    align-items: center;
    border: 1px solid #1D5769;
    font-family: "Lato";
    font-size: 15px;
    font-weight: 700;
    margin: auto;
}

.PasswordErrorSpan {
    font-size: 12px;
    font-weight: 400px;
    color: #25282B;
}

.PasswordErrorContainer {
    display: flex;
    flex-direction: column;
    width: 245px;
    height: 120px;
    background-color: "white";
    /* align-self: center;
    border-radius: 10px; */
    padding: 10px;
    /* box-shadow: 0px 20px 24.200000762939453px 0px #0000000F; */
}