.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 90%;
    max-height: 90%;
    width: 90%;
    height: 90%;
    overflow: hidden;
    position: relative;
}

.modalAudio {
    max-width: 30%;
    max-height: 30%;
}

.audioContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.chartContainer {
    padding: 20px;
    height: 100%;
    width: 100%;
}

.closeButton {
    background: none;
    border: none;
    color: #787878;
    font-size: 24px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
}

.modalTitle {
    text-align: center;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: bold;
}

.modalSubtitle {
    text-align: center;
    margin-bottom: 20px;
    font-size: 16px;
    color: #787878;
}
