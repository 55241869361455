.sidebar {
  background-color: #fff;
  transition: width 0.6s ease;
  height: 100vh;
}

.sidebar-open-scroll {
  padding-right: 5px;
  overflow-y: hidden;
}

.sidebar-open-scroll:hover {
  overflow-y: scroll;
  padding-right: 0px;
}

.hamburger {
  font-size: 24px;
  cursor: pointer;
  z-index: 2;
  display: flex;
}

.sidebar-menu {
  list-style: none;
  padding: 0;
  transition: margin 0.3s ease;
}

.sidebar-menu li {
  padding-left: 10px;
  margin: 0px;
  cursor: pointer;
  color: #696969;
  font-size: 14px;
  font-weight: 500;
  height: 34px;
  display: flex;
  align-items: center;
  font-family: "Roboto";
  letter-spacing: 0.15px;
}

.sidebar-menu li:hover {
  background-color: rgba(203, 243, 210, 0.4);
  /* border-left: 3px solid #1D352D; */
}

.sidebar-menu li span {
  margin-left: 5px;
}

.sidebar-menu li.category-header {
  color: var(--Colors-nav-subtitle, #4F4F4F);
  display: flex;
  align-items: center;
}

.sidebar-menu li.category-header:hover {
  background-color: white !important;
}

.category-header-title {
  color: var(--Colors-nav-subtitle, #4F4F4F);
  font-family: 'Roboto';
  font-size: 16px;
  font-style: Bold;
  font-weight:590;
  line-height: 28px;
  letter-spacing: 0.0015em;
  margin-left: 8px !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sidebar-menu li.blank-header {
  height: 10px;
}

.sidebar-menu li.selected {
  background-color: #CBF3D2;
  border-left: 3px solid #1D352D;
  color: #000;
}

.date-style {
  color: var(--Colors-nav-date, #1D5769);

/* Subtitle 2 */
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 150% */
letter-spacing: 0.024px;
  
}

.time-style {
  color: var(--Colors-nav-time, #00162E);
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px; /* 133.333% */
}

.sidebar-minimized .time-style {
  text-orientation: upright;
  margin-top: 4px;
  font-size: 16px;
  font-weight: 300;

}

.date-time {
  display: flex;
  padding-left: 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  padding-bottom: 20px;
}

.date-time div {
  display: flex;
  padding-left: 7px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}

.sidebar-logo {
  width: 161px;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.admit-button svg {
  display: flex;
  justify-content: center;
  align-items: center;
}

.admit-button {
  display: flex;
  height: 44px;
  padding: 0px 30px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 25px;
  background: var(--Sequential-30, #496CA3);
  color: var(--Web-Colors-btn-on-tertiary-solid-default, #fff);
  font-family: Roboto;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: 0.64px;
  border: none;
}

.admit-button-container {
  display: flex;
  justify-content: center;
  padding-top: 15px;
}

.admit-button-hide {
  display: flex;
  align-items: center;
  background-color: #0CCBC3;
  color: #fff;
  border: none;
  padding: 5px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}


.sidebar-menu a {
  text-decoration: none;
  color: inherit;
  display: block;
  cursor: pointer;
  transition: background-color 0.3s;
}