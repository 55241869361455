.data-table {
  border-collapse: collapse !important;
  width: 100% !important;
}

.data-table th,
.data-table td {
  padding: 5px 0 5px 5px !important;
  text-align: left !important;
  color: #4A4A4A !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
  border-bottom: 1px solid #ddd !important;
  font-family: 'Roboto';
  gap: 5px !important;
}

.data-table th {
  font-weight: 500 !important;
  background-color: white !important;
  border-bottom: 1px solid #ddd !important;
  height: 50px;
}

.data-table thead {
  position: sticky;
  top: 0;
  z-index: 10;
}

.data-table tbody tr {
  height: 52px;
}

.icon-cell {
  display: flex !important;
  align-items: center !important;
}

.separator-row {
  display: none !important;
}

.separator-row td {
  border: none !important;
  height: 1px !important;
  background-color: #ddd !important;
}

.grid-cell {
  padding: 8px !important;
  text-align: center !important;
  vertical-align: middle !important;
  gap: 5px !important;
}

.icon-container {
  display: flex !important;
  align-items: center !important;
  gap: 5px !important;
}

.icon-text {
  font-size: 12px !important;
  /* font-weight: bold !important; */
}

.hovered-row {
  background-color: #F3F3F3 !important; 
}

.selected-row {
  background-color: white !important;
}

.update-button {
  border-radius: 25px !important;
  color: #363E6B !important;
  height: 35px !important;
  padding: 0px 16px !important;
  background-color: #FAFAFA !important;
  border: 1px solid #363E6B;
  font-size: 12px !important;
  font-weight: 700;
}

.more-options-update-button {
  height: 30px !important;
  padding: 0px 15px !important;
  background-color: transparent;
  border: none !important;
  padding-bottom: 50%;
}


.empty{
  text-align: center !important;
}

.sorting-icon {
  display: flex;
  flex-direction: column;
  padding: 0 5px 0 5px;
}

.sorting-icon div {
  height: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.resizable-column {
  position: relative;
  display:flex;
  align-items: center;
  height: 75%;
}

.resizable-column::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 4px; 
  height: 100%;
  cursor: col-resize;
  background-color: transparent; 
  z-index: 1;
}

.resize-divider {
  position: absolute;
  top: 0;
  right: 0;
  width: 4px; 
  height: 100%;
  cursor: col-resize;
  background-color: transparent; 
  z-index: 2;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pagination {
  display: flex;
  align-items: center;
}

.pagination button {
  margin-left: 5px !important;
  cursor: pointer !important;
  padding: 1px 8px !important;
  background-color: #1D5769 !important;
  border: none;
  border-radius: 5px !important;
}

.pagination label {
  font-size: 12px !important;
}

.pagination select {
  font-size: 12px !important;
}

.active {
  background-color: #679be9 !important;
  color: #fff ;
}

.HeaderFilterMenu {
  padding: 20px 10px;
  margin-top: 10px;
  min-width: 132px;
  width: auto;
  height: auto;
  gap: 10px;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: 0.5px solid #CFCFCF;
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.10);
  position: absolute;
  z-index: 3;
  transform: translateX(-90%);
}


.HeaderFilterItem {
  color: #3B3E40;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.2px;
  display: flex;
  white-space: nowrap;
}

.row-value-link {
  cursor: pointer;
}

.row-value-link:hover{
  /* font-weight: bold; */
  color: black;
}

.GridInitialsIcon {
  z-index: 2;
  justify-content: center;
  display: flex;
  color: #FFF;
  text-align: center;
  font-family: "Roboto";
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 160% */
  letter-spacing: 0.024px;
  position: absolute;
  width: 100%;
}

.chip-container {
  display: inline-flex;
  height: 30px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 22px;
  background: var(--Colors-bg-chip, #D6D6D6);
  color: var(--Colors-text-chip, #212121);
  text-align: center;
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.column {
  position: relative;
  vertical-align: middle !important;;
  height: 100%;
}

.resizer {
  background: #ddd;
  cursor: col-resize;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 0.15rem;
}
.resizer:hover {
  background: #ddd;
}