.HubsLabel {
    color: var(--Neutral-Black, #25282B);
    font-family: "Roboto";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0.03px;
}

.HubsNumber {
    color: var(--Secondary-400, #30584A);
    text-align: center;
    font-family: "DM Serif Display";
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 40px; /* 125% */
}

.PopupContainer {
    position: absolute;
    display: inline-flex;
    padding: 28px;
    flex-direction: column;
    align-items: flex-start;
    background-color: #FFFFFF;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 6px;
    gap: 5px;
    box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.10);
    width: 356px;
    height: 321px;
}

.HubBody2 {
    color: #5E5E5E;
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.04px;
}

.PopupListItems {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.PopupListItems > div {
    display: flex;
    column-gap: 5px;
    align-items: center;
}

