.role-inputs {
    height: 100%; /* Adjust this to fit the container's height */
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: auto;

    /* Hide scrollbar for IE, Edge, and Firefox */
    -ms-overflow-style: none;  /* Internet Explorer and Edge */
    scrollbar-width: none; /* Firefox */

    /* Hide scrollbar for Chrome, Safari, Opera */
    &::-webkit-scrollbar {
        display: none;
    }
}


.role-input {
    height: 44px;
    border-radius: 6px;
    border: 0.5px solid #929395;
    width: 123px;
    flex-shrink: 0; 
    padding: 10px 12px;
    margin-bottom: 10px; 
}

.permissions-scroll-container {
    max-height: calc(100% - 50px);
    overflow-y: auto;
    scrollbar-width: none;
}

.permission-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.permission-checkbox {
    display: flex;
    height: 28px;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;
    align-self: stretch;
}

.localDisabledButton {
    background-color: gray;
    color: white;
    cursor: not-allowed;
}

.manage-role-permissions {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.permissions-scroll-container {
    flex: 1;
    overflow-y: auto;
    max-height: calc(100% - 50px); /* Adjust the 60px to the height of your button + margin */
    scrollbar-width: none;
}

.permission-checkbox input[type="checkbox"] {
    width: 14px;
    height: 14px;
    flex-shrink: 0;
    accent-color: #1D5769;
}

.permissions-title {
    color: #5E5E5E;
    font-family: "Segoe UI";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.permission-name {
    color: #4A4A4A;
    font-family: Lato;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px; /* 261.538% */
    letter-spacing: 0.2px;
    justify-content: center;
}

.permission-item input[type="checkbox"] {
    margin-right: 10px;
    appearance: none;
    width: 16px;
    height: 16px;
    border: 1px solid #333;
    border-radius: 3px;
    cursor: pointer;
}

.permission-item input[type="checkbox"]:checked {
    background-color: #333;
}

.permission-item label {
    cursor: pointer;
}
