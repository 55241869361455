.PainViewContainer {
    display: flex;
    align-items: center;
    width: 100%;
    height: 360px;
    padding: 0; /* Ensure no extra padding affecting positioning */
    margin: 0;
}

.IconWrapper {
    transform: scale(0.6);
    padding: 0;
    margin: 0;
}

.BodySectionWrapper {
   display: flex;
   position: absolute;
}

.BodyContainer {
    display: flex;
    justify-content: center;
    position: relative;
}

.BodyBackground {
    display: flex;
    position: absolute;
    top: 0;
}

