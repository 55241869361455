/* CardDetails.module.css */
.DetailsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    height: 100%;
}

.Detail {
    padding: 10px 0px;
    width: 100%;
    border-bottom: 0.5px solid #BEBFBF;
    color: var(--Neutral-900, #212121);
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.04px;
    display: flex;
    column-gap: 10px;
    align-items: center;
}


.DetailLabel {
    width: 250px;
    min-width: 250px;
}

.DetailLabelReports {
    width: 193px;
}

.ProgramCountdown {
    width: 300px;
    height: 14px;
    flex-shrink: 0;
    border-radius: 8px;
    background: var(--Colors-bg-loading-bar, #D6D6D6);  
}

.DaysLeft {
    height: 14px;
    flex-shrink: 0;
    background-color: #68A691;
}

/* Questionnaire details styles */
.Question {
    border-bottom: 0.5px solid var(--Neutral-300, #B9B9B9);
    display: flex;
    padding: 8px 0px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
}

.QuestionTitle {
    color: #66696B;
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.024px;
}

.Question > span {
    padding: 6px 10px 6px 0px;
    color: #25282B;
    font-family: "Lato";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 123.077% */
    letter-spacing: 0.1px;
}

.ReportsTabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 10px 0;
    border-bottom: 0.5px solid var(--Colors-on-tab-default, #686869);
}

.ReportsTabs div {
    display: flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    line-height: 24px; /* 150% */
    z-index: 2;
}

.NoteTab {
    color: var(--Colors-on-tab-default, #686869);
    font-weight: 400;
    letter-spacing: 0.04px;
    cursor: pointer;
}

.NoteTabActive {
    background: #E1ECEF;
    color: #30584A;
    font-weight: 500;
    letter-spacing: 0.024px;
    border-bottom: 2px solid var(--Colors-on-tab-active, #30584A);
    cursor: pointer;
}

.NewNoteSection {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.NewNote {
    max-height: 350px;
    display: flex;
    min-width: 208px;
    padding: 10px 12px;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
    min-height: 120px;
    border-radius: 6px;
    border: 0.5px solid var(--Colors-ol-input-default, #686869);
    background: var(--Colors-bg-input-default, #FFF);
    color: var(--Colors-text-label-default, #4F4F4F);
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: 0.024px;
}

.NewNoteButton {
    display: flex;
    height: 40px;
    padding: 20px;
    width: 106px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 6px;
    background: var(--Web-Colors-btn-bg-primary-default, #1D5769);
    border: none;
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    letter-spacing: 0.64px;
}
  
.NewNoteButton:hover {
    background: #00162E;
}
  
.NotesTitle {
    color: var(--Colors-on-background, #373737);
    font-family: "Roboto";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 140% */
    letter-spacing: 0.03px;
}

.ReportNotesContainer {
    display: flex;
    width: 699px;
    padding: 10px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    color: var(--Neutral-900, #212121);
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.04px;
    border-bottom: 0.5px solid #D6D6D6;
}

.CloseModalButton {
    color: var(--Web-Colors-btn-on-secondary, #00162E);
    text-align: center;
    font-family: "Roboto";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    letter-spacing: 0.64px;
    border-radius: 6px;
    border: 1px solid var(--Web-Colors-btn-ol-secondary-default, #00162E);
    background: var(--Web-Colors-btn-bg-secondary-default, #FFF);
}

.QScoreBadge {
    color: #FFF;
    font-family: "Roboto";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
}

.QScoreHeaderItems {
    color: #4A4A4A;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    letter-spacing: 0.04px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.QScoreTitle {
    font-size: 20px;
    border-right: 1px solid #B9B9B9;
    padding-right: 10px;
}

.QScoreSubHeader {
    font-size: 16px;
    padding-left: 10px;
}