.cardContainer {
  display: flex;
  flex-direction: column;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 0px;
}

.header {
  display: flex;
  align-items: center;
  padding-top: 16px;
}

.headerSection {
  display: flex;
  align-items: center;
  padding: 0 16px;
}

.divider {
  position: relative;
}

.divider::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 24px;
  width: 1px;
  background-color: #ccc
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.vitalName {
  font-weight: bold;
  font-size: 16px;
  margin-left: 8px;
}

.measurement {
  display: flex;
  align-items: baseline;
}

.value {
  font-size: 16px;
  font-weight: bold;
  margin-right: 4px;
}


.latestDate {
  font-size: 12px;
  color: #666;
}

.chartWrapper {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 5%;
}

.leftPanel {
  width: 100%;
}

.rightPanel {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rightPanel canvas {
  width: 100% !important;
}

.rightPanel audio {
  width: 100%;
  height: 100%;
}

.questionnairePanel {
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.10);
  background-color: #fff;
  padding: 16px;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;
  border: 0.8px solid var(--neutral-border, #E6E8F0);
}

.boxContainer {
  padding: 10px;
  margin: 10px;
  text-align: center;
  width: 100%;
  justify-content: space-between;
  height: 100%;
  display: flex;
}

.topLabel {
  font-size: 20px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: #515772;
}

.displayLabel {
  color: var(--Accent-500, #212241);
  font-family: "DM Serif Display";
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  text-align: left;
}

.updateTime {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.PerinTooltip {
  border: 3px !important;
  padding: 0px 10px !important;
  background-color: azure !important;
}

.customModal .modal-dialog {
  max-width: 80%;
}
