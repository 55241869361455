  .FilterManagmentView {
    display: flex;
    width: 100%;
    padding: 8px 0px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd !important;
  }
  
  .HeaderContent {
    color: #006969;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-left:10px
  }
  
  .LeftSection {
    display: flex;
    color: #515772;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    gap: 10px;
  }

  .TaskSection {
    display: flex;
    color: #515772;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    gap: 10px;
    padding: 13px 0px;
  }
  
  .RightSection {
    display: flex;
    gap: 3px;
    justify-content: space-between;
    flex-wrap: wrap !important;
  }
  
  .SearchInput {
    background: #FFF;
    width: 12vw;
    height: 44px;
    text-align: left;
    text-indent: 15px;
    font-weight: 500;
    font-size: 14px;
    padding-left: 15px;
    border: 1px solid #9F9F9F;
    border-radius: 8px;
  }
  
  .DropdownComponent {
    display: flex;
    width: 10vw;
    height: 6vh;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 0.5px solid #929395;
    padding: 12px 10px 0 12px;
    min-width: 10vw;
  }
  
  .DeleteButton, .DeleteButtonDisabled {
    border-radius: 25px !important;
    font-size: 15px;
    font-weight: 700;
    height: 44px;
    background: white !important;
    display: flex;
    column-gap: 3px;
    align-items: center;
    justify-content: center;
    padding: 0px 20px;
  }

  .DeleteButton {
    color: black !important;
    border: 1px solid #000 !important;
  }

  .DeleteButtonDisabled {
    border: 1px solid #D6D6D6 !important;
    color:  #D6D6D6 !important;
  }



  .AddContentButton, .AddContentButtonDisabled {
    border: none !important;
    height: 44px;
    border-radius: 25px;
    font-size: 15px;
    font-weight: 700;
    padding: 0px 20px;
  }
  
  .AddContentButton {
    background: #B7C0EE !important;
    color: #212241 !important;
  }

  .AddContentButtonDisabled {
    background: #D6D6D6 !important;
    color:#B9B9B9 !important;
  }
  
  .DeleteButtonContent {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .UpdateContentButton {
    border-radius: 25px !important;
    background: #006969 !important;
    color: #FFF !important;
    height: 30px !important;
    padding: 0px 15px !important;
    border: none !important;
  }
  
  .AddLinkButton {
    border-radius: 8px !important;
    background: #E9E9EA !important;
    color: #313131 !important;
    border: none !important;
  }
  
  .CheckButton {
    border-radius: 0px 8px 8px 0px !important;
    background: #BEBFBF !important;
  }

  .FloatingLabel {
    color: #929395;
    font-family: "Lato";
    font-weight: 400;
    line-height: 16px;
    padding: 3px 0 0 12px;
    position: absolute;
    font-size: 12px;
  }

  .AddDropdownMenu {
    background-color: #FFFFFF;
    border: 0.5px solid #CFCFCF;
    box-shadow: 1px 1px 15px 0px #0000001A;
  }

  .AddDropdownItem {
    font-size: 13px;
  }

  .AddDropdownItem:hover {
    background-color: #B2C7CE;  
  }

  .ClearAllLabel {
    color: var(--Neutral-Black, #000);
    text-align: right;
    font-family: "Roboto";
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.64px;
    cursor: pointer;
    padding-right: 5px;
  }

  