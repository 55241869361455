.main-container {
  display: flex !important;
  height: 100vh !important;
  transition: margin-left 0.3s !important;
}

.sidebar {
  background-color: #FFF !important;
  transition: width 0.3s !important;
}

.sidebar-opened {
  width: 270px !important;
}

.sidebar-minimized {
  width: 50px !important;
}

:root {
  --bg-image-width: calc(100% - 200px);
}

.background-container {
  background-image: url('../assets/loginBackground.svg');
  background-size: var(--bg-image-width) auto;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

@media (max-width: 768px) {
  :root {
    --bg-image-width: calc(100% - 80px);
  }
}

.main-content {
  flex: 1 !important;
  background-color: #F3F3F3 !important;
  display: flex !important;
  flex-direction: column !important;
  transition: margin-left 0.3s !important;
  min-height: 100vh !important; /* Ensure it's at least full viewport height */
  height: auto !important; /* Allow it to grow beyond 100vh if needed */
  overflow-y: auto !important;
}

.sidebar-open .main-content {
  margin-left: 0px !important;
}

.sidebar-closed .main-content {
  margin-left: 0px !important;
}

.full-width-content {
  width: 100% !important;
}

.content-with-sidebar {
  width: calc(100% - 270px) !important;
}

.top-content {
  border: 1px solid #ccc !important;
  margin-top: 10px !important;
  overflow: auto !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 10px !important;
}

.date-content {
  color: #006969 !important;
  font-size: 32px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  padding-left: 10px !important;
}

.userInfo-content {
  color: #4a4a4a !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  display: inline-flex !important;
  align-items: center !important;
  gap: 5px !important;
  padding-top: 3px;
  padding-right: 5px;
}

.middle-content {
  height: 35% !important;
  display: flex !important;
  justify-content: space-around !important;
  align-items: flex-start !important;
  padding-top: 15px !important;
  padding-left: 10px !important;
}

.card-content {
  width: 60% !important;
  display: flex !important;
  flex-wrap: wrap !important;
  gap: 14px !important;
  padding-top: 34px !important;
}

.card {
  flex: 0 0 calc(25% - 4px) !important;
}

.appointment-content {
  width: 40% !important;
  flex-wrap: wrap !important;
  gap: 4px !important;
  padding-right: 10px !important;
}

.appointment {
  flex: 0 0 calc(50% - 4px) !important;
}

.appointment-container {
  display: flex !important;
  flex-direction: column !important;
  border-radius: 15px !important;
  background: #f8f8f8 !important;
}

.text-calendar-container {
  display: flex;
}

.grid-container {
  display: flex !important;
  width: 100% !important;
  justify-content: space-between !important;
  align-items: baseline !important;
}

.left-text {
  font-weight: bold !important;
}

.date-component {
  font-style: italic !important;
  color: #555 !important;
}

.date-calendar {
  width: 100px !important;
}

.date-picker-container {
  display: flex !important;
  align-items: center !important;
}

.date-label {
  font-size: 16px !important;
  padding: 8px !important;
  border-radius: 5px !important;
  margin-right: 10px !important;
}

.arrow-button {
  font-size: 16px !important;
  padding: 8px !important;
  border-radius: 5px !important;
  cursor: pointer !important;
  background-color: #fff !important;
  transition: background-color 0.3s !important;
}

.arrow-button:hover {
  background-color: #f0f0f0 !important;
}

.react-datepicker-wrapper {
  position: relative !important;
}

.react-datepicker {
  font-size: 14px !important;
}

.react-datepicker__input-container {
  position: relative !important;
  width: 100% !important;
}

button,
input,
optgroup,
select,
textarea,
label {
  font-family: inherit !important;
  font-size: 14px;
  line-height: inherit !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1070;
}

.close-out-button {
  align-self: end;
  cursor: pointer;
  height: 5px;
}

input[type="radio"]:checked {
  accent-color: #1D5769 !important;
  background-color: #1D5769 !important;
}

input[type=radio]:disabled {
  border: 1px solid #929395 !important;
}

;

input[type="checkbox"]::after {
  background-color: #68A691;
}

/* select {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17' fill='none'><path d='M14.6392 1H1.38021C1.21917 1 1.12417 1.18061 1.2154 1.31331L6.46481 8.94881C6.48773 8.98215 6.5 9.02166 6.5 9.06212V15.6967C6.5 15.8403 6.64681 15.9371 6.77878 15.8805L9.87878 14.5519C9.95232 14.5204 10 14.4481 10 14.3681V9.05736C10 9.01988 10.0105 8.98315 10.0304 8.95136L14.8088 1.306C14.892 1.17279 14.7962 1 14.6392 1Z' fill='#457968' stroke='#457968' stroke-width='0.8'/></svg>");
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 5px;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  margin-right: 2rem;
  padding: 1rem;
  padding-right: 2rem;
} */

.form-check-input:checked {
  background-color: #68A691 !important;
  border-color: #68A691 !important;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: transparent;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 5px;
}

.subtitle-1 {
  /* Subtitle 1 */
  color: var(--Neutral-Black, #25282B);
  text-align: center;
  font-family: "Roboto";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* 140% */
}

.subtitle-2 {
  color: var(--Colors-text-cell, #212121);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.024px;
}

.caption {
  color: var(--Neutral-700, #4F4F4F);

  /* Caption */
  font-family: "Roboto";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 133.333% */
}

.body-2 {
  color: var(--Colors-text-cell, #212121);
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.04px;
}

.headline-4 {
  /* Headline 4 */
  color: var(--Colors-on-surface, #4F4F4F);
  font-family: "DM Serif Display";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  /* 125% */
}

.headline-6 {
  /* Headline 6 */
  color: #686869;
  font-family: "Roboto";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  /* 140% */
  letter-spacing: 0.03px;
}

.headline-5 {
  color: var(--Colors-on-surface, #4F4F4F);
  /* Headline 5 */
  font-family: "Roboto";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  /* 133.333% */
}

.help-icon .dropdown-toggle::after {

  display: none;
}

.help-icon .dropdown-toggle {
  padding: 0;
  background: none;
  border: none;
}

.help-icon .dropdown-toggle>span {
  display: flex;
  align-items: center;
}

.profile-initials {
  color: #000;
  font-family: "Poppins";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.vitals-modal {
  width: 90vw !important;
  max-width: 90vw !important;
}

.user-fullName {
  color: var(--Colors-on-background, #373737);

  /* Subtitle 2 */
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 150% */
  letter-spacing: 0.024px;
}

.selectedMenuItem {
  padding-left: 20px;
  font-size: 32px;
  font-weight: 700;
  line-height: 43px;
  letter-spacing: 0em;
  color: #00162E;
  text-align: left;
  font-family: "DM Serif Display";
  padding-bottom: 5px;
  padding-top: 5px;
}