.styleTypeNone {
  list-style-type: none;
}

input[type="checkbox"] {
  display: grid;
  place-content: center;
  accent-color: #006969;
}

input[type="checkbox"]::before {
  content: "";
  width: 1em;
  height: 1em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  color: white;
  box-shadow: inset 1em 1em var(--form-control-color);
}

input[type="checkbox"]::after {

  color: white;
  
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

/* .form-check-input:active {
  background-color: #68A691 !important;
  border-color: #68A691 !important;
} */
/* 
.form-check-input:focus {
  border-color: #6668 !important;
  outline: 0;
  box-shadow: none !important;
} */

.ListText{
  color: #212121;
  font-family: "Roboto";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px; 
  letter-spacing: 0.04px;
  padding-left: 10px;
}

.SearchInput{
  border-radius: 6px;
  border: 0.5px solid #929395;
  display: flex;
  height: 40px;
  min-width: 145px;
  padding: 10px 12px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  margin-bottom: 25px;
}

.organizationBorder {
  border-bottom: .25px solid #C5C7C9; 
  padding-bottom: 5px; 
}

.groupBorder {
  border-bottom: .25px solid #C5C7C9; 
  padding-bottom: 5px;
}
