.MainContent {
    background-color: transparent;
    width: 96%;
    margin: 0 2%;
    display: flex;
    flex-direction: column;
    transition: margin-left 0.3s;
    flex: 1;
}

.MiddleContent, .MiddleContentAdd {
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
    background-color: #FFFFFF;
    justify-content: space-between;
    width: 100%;
    border-radius: 8px;
    box-shadow: 0px 20px 25px 0px rgba(0, 0, 0, 0.15);
    height: 89vh;
    padding-top: 20px;
}

/* .MiddleContentAdd {
    height: 85vh;
} */

.Title {
    font-size: 32px;
    font-weight: 700;
    line-height: 43px;
    letter-spacing: 0em;
    color: #00162E;
    text-align: left;
    font-family: "DM Serif Display";
    padding-bottom: 20px;
}

.DeleteButton {
    border: 0.5px solid #000000;
    background-color: white;
}

.DeleteButton, .AddButton, .DeleteButtonDisabled {
    width: 109px;
    height: 36px;
    border-radius: 8px;
    font-size: 12px !important;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0em;
}

.DeleteButtonDisabled{
    border: 0.5px solid #000;
    opacity: 0.35;
    background-color: #D1D1D1;
    cursor: not-allowed !important;
}

.AddButton {
    border: 0.5px solid #1D5769;
    background-color: #1D5769;
    color: white;
}

.DeleteButton:hover{
    color: black;
}

.ContentTitle {
    padding-bottom: 10px;
    font-size: 32px;
    font-weight: 700;
    line-height: 37px; 
    color: #4F4F4F;
    font-family: "DM Serif Display";
}

.ReturnToManagement {
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #4A4A4A;
    cursor: pointer;
    margin-bottom: 0px;
    padding: 10px 0px;
    max-width: fit-content;
    align-items: center;
    display: flex;
    column-gap: 5px;
}

.ReturnToManagementBlankSpace {
    height: 40px;
}

.CreateButton, .NextButton, .BackButton, .UpdateButton, .CancelButton {
    border: none;
    font-size: 16px !important;
    font-weight: 700;
    width: auto;
    height: 5vh;
    border-radius: 6px;
    line-height: 24px; /* 150% */
    letter-spacing: 0.64px;
    font-family: "Roboto";
    font-style: normal;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 116px;
}

.CreateButton, .CreateButton:hover {
    background-color: #1D5769;
    color: white;
    border: 1px solid #1D5769;
}

.NextButton, .NextButton:hover {
    background-color: white;
    color: #1D5769;
    border: 1px solid #1D5769;
}

.UpdateButton, .UpdateButton:hover {
    background-color: #1D5769;
    color: white;
    border: 1px solid #1D5769;
}

.CreateButton:disabled {
    background-color: #D6D6D6;
    border: 1px solid #D6D6D6;
}


.BackButton, .BackButton:hover  {
    background-color: white;
    color: #1D5769;

}

.CancelButton, .CancelButton:hover {
    background-color: white;
    color: #BEBFBF;
    border: 1px solid #BEBFBF;
}

.form-floating>label {
    color: red;
}

.ValidationMessage {
    font-size: 10px;
    color: red;
}

.ValidationMessageContainer {
    display: flex;
    column-gap: 5px;
    padding-left: 5px;
}

.BorderedComponent, .BorderedComponentTextArea {
    font-family: "Roboto" !important;
    font-size: 16px !important;
    font-weight: 400;
    line-height: 24px !important;
    width: 15vw;
    border: 0.5px solid var(--Colors-ol-input-default, #686869); 
    border-radius: 6px;
    color: #000;
    padding-right: 12px;
}

.BorderedComponent {
    height: 44px !important;
    min-height: 44px !important;
    padding: 10px 12px;
}
  
  /* text area */
.BorderedComponentTextArea {
    min-height: 102px !important;
    padding-top: 15px !important;
    max-height: 300px !important;
    border-color: #686869;
}
  
  /* placeholder */
.BorderedComponent~label, .BorderedComponentTextArea~label{
    padding: .75rem .75rem !important;
    color: #4F4F4F;
    font-family: "Roboto" !important;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.024px;
    font-size: 16px !important;
}
  
  /* floating label in input when active */
.BorderedComponent:focus~label , .BorderedComponent:not(:placeholder-shown)~label,
.BorderedComponentTextArea:focus~label , .BorderedComponentTextArea:not(:placeholder-shown)~label
{
    padding-top: 10px !important;
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
    height: auto !important;
    font-size: 13px !important;
}
  
  /* text in input when active */
.BorderedComponent:focus, .BorderedComponent:not(:placeholder-shown) {
    padding-top: 10px !important;
    padding-bottom: 0px !important;
}
.Container {
    height: 77vh;
    margin: 0;
    padding: 0;
}

.form-select {
    height: 6vh !important;
    width: 15vw !important;
}

.MainCard {
  height: 89vh;
  border-radius: 8px;
  box-shadow: 0px 20px 25px 0px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  padding: 16px 20px;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;
  justify-content: space-between;
  border: 0.8px solid var(--neutral-border, #E6E8F0);
}

.FormContainer {
  margin-bottom: 80px;
  width: 50vh;
}

.FormLabel {
    color: #4F4F4F;
    font-family: "Roboto";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
}

select.form-select {
    min-height: 44px !important;
    width: 15vw !important;
}

.DescriptionCount {
    width: 15vw;
    font-size: 10px;
    color: rgb(146, 147, 149);
    display: flex;
    justify-content: flex-end;
}
  
  /* Input styles */

.styled-input-container {
    position: relative;
    display: flex;
    flex-direction: column;
}

/*  common input styles */
.styled-input-container input, .styled-input-select, .note-input {
    font-family: "Roboto" !important;
    font-weight: 400;
    line-height: 24px !important;
    width: 15vw;
    border: 0.5px solid var(--Colors-ol-input-default, #686869); 
    border-radius: 6px;
    color: #000;
}

@media (min-width: 1156px) {
    select, select option, styled-input-container input,styled-input-select {
      font-size: 14px; 
    }
  }
  
  @media (max-width: 1155px) {
    select, select option, styled-input-container input, styled-input-select {
      font-size: 12px; 
    }
  }

.styled-input-container:focus-within label,
.styled-input-container .filled {
    transform: translate(2.5px, 2px) scale(0.7);
    color: #4F4F4F;
    font-weight: 500;
    font-size: 16px !important;
    font-family: "Roboto" !important;
}

.styled-input-container label {
    position: absolute;
    pointer-events: none;
    transform: translateY(11px) scale(1);
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    padding-left: 12px;
    color: #4F4F4F;
    font-family: "Roboto" !important;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.024px;
    font-size: 16px !important;
}

.styled-input-container input {
    padding: 12px 10px 0px 10px !important;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    height: 44px;
}

.styled-input-select {
    height: 44px;
    width: 10vw;
    padding: 12px 0px 0px 7px;
}

.note-input {
    height: 100px;
    max-height: 200px;
    min-height: 44px;
    padding: 16px 12px 0 12px;
}

.styled-icon-container {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    padding-right: 10px;
}

.AddAnotherButton {
    border-radius: 25px !important;
    color: #212241 !important;
    background-color: #B7C0EE !important;
    border: 1px solid #B7C0EE !important;
    font-weight: 700;
    stroke: #212241;
    font-weight: 700;
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.ScrollContainer {
    height: 90%;
    overflow-y: scroll;
}

.ErrorMessage {
    font-size: 12px;
    color: red;
}

.TimeOutAlertContainer {
    display: flex;
    width: 100%;
    padding: 10px;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    border-radius: 10px;
    border: 1px solid var(--Primary-100, #B2C7CE);
    background: rgba(178, 199, 206, 0.30);
    margin-bottom: 10px;
}

.ReturnToOverviewManagement {
    font-size: 16px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #4A4A4A;
    cursor: pointer;
    margin-bottom: 0px;
    padding: 10px 0px;
    max-width:100%;
    align-items: center;
    display: flex;
    column-gap: 5px;
}
